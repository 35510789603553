import L from 'leaflet';
import React, {Component} from 'react';


class Mapa extends Component{

    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidMount = () => {
        //20.702376779484947, -103.44640501362214
        const position = [20.702376779484947, -103.44640501362214]
        const map = L.map('map').setView(position, 17)
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map);
    
        L.marker(position)
          .addTo(map)
          .bindPopup('Dr. Javier Guerrero | Cirujano Plástico')
          .openPopup();
    }

    render(){
        return [
            <div style={{zIndex:"0", position:"relative"}} >
                <div  id="map" className="map"  style={{height:'512px', width:'100%'}}></div>
            </div>
        ]
    }

}

export default Mapa;