import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import cf1 from '../Images/4-Cirugia-facial/1-PORTADA-1400.png';
import cf2 from '../Images/ICONOS/13.png';
import cf3 from '../Images/ICONOS/14.png';
import cf4 from '../Images/ICONOS/2.png';
import cf5 from '../Images/ICONOS/17.png';
import cf6 from '../Images/ICONOS/20.png';
import cf7 from '../Images/ICONOS/15.png';


const Cirugia_Facial = props => {

    return (
        <Fragment>

            <img className="img-cc" src={cf1} alt=""></img>

            <h1 style={{marginBottom: "100px", marginTop:"-120px"}}>
                <FormattedMessage
                id="header.3"
                defaultMessage="Cirugía Facial"
                />
            </h1>

            <div className="padre_1" style={{marginBottom:"200px"}}>

            <a href='/ritidoplastia' style={{color:"black"}}>
                    <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={cf2} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="cf.2"
                            defaultMessage="Ritidoplastia"
                            />
                        </p>
                        <p style={{fontSize:"22px", marginTop:"-10px"}}>
                            <FormattedMessage
                            id="cf.2.1"
                            defaultMessage="Lifting Facial"
                            />
                        </p>
                    </div>
                </a>

            <a href='/rinoplastia' style={{color:"black"}}>
                    <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={cf3} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="cf.1"
                            defaultMessage="Rinoplastía"
                            />
                        </p>
                    </div>
                </a>
                
            
                <a href='/blefaroplastia' style={{color:"black"}}>
                    <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={cf4} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="cf.3"
                            defaultMessage="Blefaroplastia"
                            />
                        </p>
                    </div>
                </a>

                <a href='infiltracion-de-grasa' style={{color:"black"}}>
                    <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={cf5} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="cf.4"
                            defaultMessage="Infiltración de"
                            />
                        </p>
                        <p style={{fontSize:"22px", marginTop:"-10px"}}>
                            <FormattedMessage
                            id="cf.4.1"
                            defaultMessage="Grasa en Cara"
                            />
                        </p>
                    </div>
                </a>

                <a href='/bichectomia' style={{color:"black"}}>
                    <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={cf6} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="cf.5"
                            defaultMessage="Bichectomia"
                            />
                        </p>
                    </div>
                </a>

                <a href='/otoplastia' style={{color:"black"}}>
                    <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={cf7} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="cf.6"
                            defaultMessage="Otoplastia"
                            />
                        </p>
                    </div>
                </a>
        
            </div>

        </Fragment>
    )
}

export default Cirugia_Facial;