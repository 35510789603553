import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import mmo1 from '../Images/3-Cirugia-corporal/3-Contorno corporal/3-MOMMY MAKE OVER/1-PORTADA-1400.png';
import mmo2 from '../Images/3-Cirugia-corporal/3-Contorno corporal/3-MOMMY MAKE OVER/9.png'
import mmo3 from '../Images/3-Cirugia-corporal/3-Contorno corporal/3-MOMMY MAKE OVER/CASOS-REALES/1.jpg'
import mmo4 from '../Images/3-Cirugia-corporal/3-Contorno corporal/3-MOMMY MAKE OVER/CASOS-REALES/2.jpg'
import mmo5 from '../Images/3-Cirugia-corporal/3-Contorno corporal/3-MOMMY MAKE OVER/CASOS-REALES/3.jpg'

const Mommy_Make_Over = props => {

    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });

    return (

        <Fragment>

            <img className="img-ma" src={mmo1} alt=""></img>

            <h4 style={{marginTop:"-80px"}}>
                <FormattedMessage
                id="titulos.1"
                defaultMessage="Descripción"
                />
            </h4>

            <div className='contenedor txt-separacion'>
                <p className='txt-ma' style={{marginTop:"60px"}}>
                    <FormattedMessage
                    id="mmo.1"
                    defaultMessage="Es un término popularmente usado el cual consiste en la combinación de tres procedimientos:"
                    />
                </p>

        
                <p className='txt-ma'>
                    <b>
                    <FormattedMessage
                    id="mmo.2"
                    defaultMessage="Lipoescultura:"
                    />
                    </b>
                    <FormattedMessage
                    id="mmo.3"
                    defaultMessage="Consiste en eliminar los depósitos de grasa subcutánea localizada en diferentes partes del cuerpo. El objetivo de la 
                                    operación es remodelar el contorno corporal."
                    />
                </p>

                <p className='txt-ma'>
                    <b>
                    <FormattedMessage
                    id="mmo.4"
                    defaultMessage="Abdominoplastia:"
                    />
                    </b>
                    <FormattedMessage
                    id="mmo.5"
                    defaultMessage="Consiste en la eliminación del exceso de piel y grasa abdominal y la reparación de los músculos del 
                                    abdomen que presentan flacidez."
                    />
                </p>

                <p className='txt-ma'> 
                    <b>
                    <FormattedMessage
                    id="mmo.6"
                    defaultMessage="Cirugía de mamas:"
                    />
                    </b>
                    <FormattedMessage
                    id="mmo.7"
                    defaultMessage="Consiste en la colocación de implantes de mama o levantamiento de las mismas."
                    />
                </p>

            </div>


            <div className="flexbox contenedor" style={{marginTop: "100px", marginBottom: "100px"}}>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-mmo2" src={mmo2} alt=""></img>
                </div>

                <div className='flexbox-hijo order-2'>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.3"
                        defaultMessage="Preparación/Anestesia"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="mmo.8"
                        defaultMessage="Cirugía con anestesia general y epidural."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="mmo.9"
                        defaultMessage="Este procedimiento requiere 1 noche de hospital."
                        />
                    </p>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.4"
                        defaultMessage="Cuidados Post-Operatorios"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="mmo.10"
                        defaultMessage="Reposo durante 2 a 3 semanas."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="mmo.11"
                        defaultMessage="Regreso al ejercicio a partir de 6 a 8 semanas."
                        />
                    </p>

                </div>

            </div>


            <h4 style={{color:"#0E766E"}}>
                <FormattedMessage
                id="titulos.5"
                defaultMessage="¿Necesitas un procedimiento personalizado?"
                />
            </h4>

            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-3" href='/cita'>
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>


            <h4>
                <FormattedMessage
                id="titulos.6"
                defaultMessage="Casos reales:"
                />
            </h4>

            <div className='container row'>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={mmo3} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={mmo4} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={mmo5} alt=""></img>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Mommy_Make_Over;