import React, { Fragment, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import { langContext } from '../Context/langContext';

import ma1 from '../Images/3-Cirugia-corporal/2-mamas/AUMENTO/1-PORTADA-1400.png';
import ma2 from '../Images/3-Cirugia-corporal/2-mamas/AUMENTO/2.png'
import ma3 from '../Images/3-Cirugia-corporal/2-mamas/AUMENTO/CASOS-REALES/1.png'
import ma4 from '../Images/3-Cirugia-corporal/2-mamas/AUMENTO/CASOS-REALES/2.png'
import ma5 from '../Images/3-Cirugia-corporal/2-mamas/AUMENTO/CASOS-REALES/3.png'
import ma6 from '../Images/3-Cirugia-corporal/2-mamas/AUMENTO/CASOS-REALES/4.jpg'
import ma7 from '../Images/3-Cirugia-corporal/2-mamas/AUMENTO/CASOS-REALES/5.png'
import ma8 from '../Images/3-Cirugia-corporal/2-mamas/AUMENTO/CASOS-REALES/6.png'



const Mamoplastia_de_Aumento = props => {

    const idioma = useContext(langContext).obtenerLenguaje();

    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(elems_2);
    });

    return (

        <Fragment>

            {idioma === "es-MX" ? (
                <img className="img-ma" src={ma1} alt=""></img>
            ) : (
                <img className="img-ma" src={ma1} alt=""></img>
            )}

            <h4 style={{marginTop:"-80px"}}>
                <FormattedMessage
                id="titulos.1"
                defaultMessage="Descripción"
                />
            </h4>

            <div className='contenedor txt-separacion'>
                <p className='txt-ma' style={{marginTop:"60px"}}>
                    <FormattedMessage
                    id="ma.1"
                    defaultMessage="Consiste en la colocación de implantes de mama, se pueden colocar en diferentes planos, lo más común es 
                                    por debajo del músculo o por arriba del mismo."
                    />
                </p>

                <p className='txt-ma'>
                    <FormattedMessage
                    id="ma.2"
                    defaultMessage="Existen diferentes tipos de implante, los de uso más común están hechos de gel de silicón altamente cohesivo."
                    />
                </p>
            </div>


            <div className="flexbox contenedor" style={{marginTop: "100px", marginBottom: "100px"}}>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-ma2" src={ma2} alt=""></img>
                </div>

                <div className='flexbox-hijo order-2'>

                    <h4 className="cambio-posicion">
                        <FormattedMessage
                        id="titulos.2"
                        defaultMessage="¿Quiénes son candidatos?"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="ma.3"
                        defaultMessage="Pacientes que tengan poco volumen en las mamas y desean aumentarlo."
                        />
                    </p>

                    <h4 className="cambio-posicion">
                        <FormattedMessage
                        id="titulos.3"
                        defaultMessage="Preparación/Anestesia"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="ma.4"
                        defaultMessage="Cirugía con anestesia local y sedación."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="ma.5"
                        defaultMessage="Cirugía ambulatoria."
                        />
                    </p>

                    <h4 className="cambio-posicion">
                        <FormattedMessage
                        id="titulos.4"
                        defaultMessage="Cuidados Post-Operatorios"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="ma.6"
                        defaultMessage="Cirugía con anestesia local y sedación."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="ma.7"
                        defaultMessage="Cirugía ambulatoria."
                        />
                    </p>

                </div>

            </div>


            <h4 style={{color:"#0E766E"}}>
                <FormattedMessage
                id="titulos.5"
                defaultMessage="¿Necesitas un procedimiento personalizado?"
                />
            </h4>

            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-3">
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>


            <h4>
                <FormattedMessage
                id="titulos.6"
                defaultMessage="Casos reales:"
                />
            </h4>

            <div className='container row'>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ma3} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ma4} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ma5} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ma6} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ma7} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ma8} alt=""></img>
                    </div>
                </div>

            </div>



        </Fragment>
    )
}

export default Mamoplastia_de_Aumento;