import React, { Fragment, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import { langContext } from '../Context/langContext';

import botox1 from '../Images/5-SIN-CIRUGÍA/BOTOX/1-PORTADA-1400.png';
import botox7 from '../Images/5-SIN-CIRUGÍA/BOTOX/1-PORTADA-1400-en.png';
import botox2 from '../Images/5-SIN-CIRUGÍA/BOTOX/15.png'
import botox3 from '../Images/5-SIN-CIRUGÍA/BOTOX/CASOS-REALES/1.jpg'
import botox4 from '../Images/5-SIN-CIRUGÍA/BOTOX/CASOS-REALES/2.jpg'
import botox5 from '../Images/5-SIN-CIRUGÍA/BOTOX/CASOS-REALES/3.jpg'
import botox6 from '../Images/5-SIN-CIRUGÍA/BOTOX/CASOS-REALES/4.jpg'



const Botox = props => {

    const idioma = useContext(langContext).obtenerLenguaje();

    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });

    return (

        <Fragment>

            {idioma === "es-MX" ? (
                <img className="img-ma" src={botox1} alt=""></img>
            ) : (
                <img className="img-ma" src={botox7} alt=""></img>
            )}

            <h4 style={{marginTop:"-80px"}}>
                <FormattedMessage
                id="titulos.1"
                defaultMessage="Descripción"
                />
            </h4>

            <div className='contenedor txt-separacion'>
                <p className='txt-ma' style={{marginTop:"60px"}}>
                    <FormattedMessage
                    id="botox.1"
                    defaultMessage="Su efecto es preventivo, corrector y rehabilitador sobre distintas alteraciones de la piel."
                    />
                </p>

                <p className='txt-ma'>
                    <FormattedMessage
                    id="botox.2"
                    defaultMessage="Arrugas de expresión: Actúa relajando los músculos de la cara que las producen. Indicado especialmente para las arrugas 
                                    de “patas de gallo”, frente y entrecejo."
                    />
                </p>

            </div>


            <div className="flexbox contenedor" style={{marginTop: "100px", marginBottom: "100px"}}>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-ma2" src={botox2} alt=""></img>
                </div>

                <div className='flexbox-hijo order-2'>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.7"
                        defaultMessage="Más información"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="botox.6"
                        defaultMessage="La duración del efecto varía dependiendo de cada persona, su estilo de vida y su tipo de piel, pero normalmente suele 
                                        mantenerse entre 4 y 6 meses."
                        />
                    </p>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="botox.7"
                        defaultMessage="Una aplicación constante y repetida cada 6 meses prolonga en gran medida su efecto."
                        />
                    </p>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="botox.8"
                        defaultMessage="En cuanto a los resultados, comienzan a ser visibles pasadas de 48 a 72 horas."
                        />
                    </p>

                </div>

            </div>


            <h4 style={{color:"#0E766E"}}>
                <FormattedMessage
                id="titulos.5"
                defaultMessage="¿Necesitas un procedimiento personalizado?"
                />
            </h4>

            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-3" href='/cita'>
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>


            <h4>
                <FormattedMessage
                id="titulos.6"
                defaultMessage="Casos reales:"
                />
            </h4>

            <div className='container row'>

                <div className='col s6 m3'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={botox3} alt=""></img>
                    </div>
                </div>

                <div className='col s6 m3'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={botox4} alt=""></img>
                    </div>
                </div>

                <div className='col s6 m3'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={botox5} alt=""></img>
                    </div>
                </div>

                <div className='col s6 m3'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={botox6} alt=""></img>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Botox;