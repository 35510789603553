import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Router from './Router/Router';
import {LangProvider} from './Context/langContext';

ReactDOM.render(
  <LangProvider>
    <Router />
  </LangProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
