import React, {Component} from 'react';
import M from 'materialize-css';
import { FormattedMessage } from 'react-intl';

import ftr1 from '../Images/1-INICIO/Innovare-transparente.png';
import ftr2 from '../Images/1-INICIO/LOGO-TRANSPARENTE.png';

class Footer extends Component {
    constructor (props) {
        super(props);
            this.state = {
        }
    }

    componentDidMount = () => {
        var elems = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(elems); 
    }

    render (){
        return [
            <footer className="page-footer fondo-1">

                <div className='container'>

                    <div style={{display:"flex"}}>

                        <div className='row'>
                            <div className='col s12 m6'>
                                
                                <h5>
                                    <FormattedMessage
                                    id="footer.1"
                                    defaultMessage="Contacto"
                                    />
                                </h5>

                                <ul>
                                    <li><p class="ftr-txt">33 1255 3538</p></li>
                                    <li><p class="ftr-txt">Av. Naciones Unidas 7972</p></li>
                                    <li><p class="ftr-txt" style={{marginTop:"-10px"}}>Fraccionamiento Villa Verona, 45129</p></li>
                                    <li><p class="ftr-txt" style={{marginTop:"-10px"}}>Zapopan, Jalisco</p></li>
                                </ul>

                            </div>

                            <div className='col s12 m6'>
                                
                                <h5>
                                    <FormattedMessage
                                    id="footer.6"
                                    defaultMessage="Cirugías Corporales"
                                    />
                                </h5>

                                <ul>

                                    <li><a class="ftr-txt" href="/mamoplastia-de-aumento">
                                        <FormattedMessage
                                        id="footer.7"
                                        defaultMessage="Mamoplastía de Aumento"
                                        />   
                                    </a></li>

                                    <li><a class="ftr-txt" href="/mastopexia">
                                        <FormattedMessage
                                        id="footer.8"
                                        defaultMessage="Levantamiento Mamario"
                                        />   
                                    </a></li>

                                    <li><a class="ftr-txt" href="/lipoescultura">
                                        <FormattedMessage
                                        id="footer.9"
                                        defaultMessage="Lipoescultura"
                                        />   
                                    </a></li>

                                    <li><a class="ftr-txt" href="/abdominoplastia">
                                        <FormattedMessage
                                        id="footer.10"
                                        defaultMessage="Abdominoplastía"
                                        />   
                                    </a></li>

                                    <li><a class="ftr-txt" href="/mommy-make-over">Mommy Make Over</a></li>

                                </ul>

                            </div>

                        </div>

                        <div className='row'>

                            <div className='col s12 m6'>

                                <h5>
                                    <FormattedMessage
                                    id="footer.2"
                                    defaultMessage="Cirugías Faciales"
                                    />
                                </h5>

                                <ul>

                                    <li><a class="ftr-txt" href="/ritidoplastia">
                                        <FormattedMessage
                                        id="footer.3"
                                        defaultMessage="Ritidoplastia (Lifting Facial)"
                                        />   
                                    </a></li>

                                    <li><a class="ftr-txt" href="/blefaroplastia">
                                        <FormattedMessage
                                        id="footer.4"
                                        defaultMessage="Blefaroplastia"
                                        />   
                                    </a></li>

                                    <li><a class="ftr-txt" href="/otoplastia">
                                        <FormattedMessage
                                        id="footer.5"
                                        defaultMessage="Otoplastía"
                                        />   
                                    </a></li>

                                    <li><a class="ftr-txt" href="/rellenos-faciales">
                                        <FormattedMessage
                                        id="footer.13"
                                        defaultMessage="Rellenos Faciales"
                                        />   
                                    </a></li>

                                </ul>   

                            </div>
                        
                            <div className='col s12 m6'>

                                <h5>
                                    <FormattedMessage
                                    id="footer.11"
                                    defaultMessage="Sin Cirugía"
                                    />
                                </h5>

                                <ul>

                                    <li><a class="ftr-txt" href="/botox">
                                        <FormattedMessage
                                        id="footer.12"
                                        defaultMessage="Toxina Botulínica (Botox)"
                                        />   
                                    </a></li>

                                    <li><a class="ftr-txt" href="/bichectomia">
                                        <FormattedMessage
                                        id="footer.14"
                                        defaultMessage="Bichectomía"
                                        />   
                                    </a></li>

                                </ul>

                            </div>

                        </div>
                    </div>

                    <div className='ftr-flexbox'>
                       
                        <div className='contenedor-ftr order-2'>
                            <img className='img-ftr1' src={ftr1} alt=""/>
                        </div>

                        <div className='contenedor-ftr'>
                            <img className='img-ftr2' src={ftr2} alt=""/>
                        </div>

                        <div className='contenedor-ftr order-1'>
                            <a class="waves-effect waves-light btn btn-cita-3" href='/cita'>
                                <FormattedMessage
                                id="index.btn-2"
                                defaultMessage="¡Agenda una cita!"
                                />
                            </a>
                        </div>
                        
                    </div>

                </div>
            </footer>
        ]
    }
}

export default Footer;