import img1 from "../Images/6-INSTALACIONES/1.jpg";
import img2 from "../Images/6-INSTALACIONES/2.png";
import img3 from "../Images/6-INSTALACIONES/3.jpg";
import img4 from "../Images/6-INSTALACIONES/4.jpg";
import img5 from "../Images/6-INSTALACIONES/5.jpg";
import img6 from "../Images/6-INSTALACIONES/6.jpg";
import img7 from "../Images/6-INSTALACIONES/7.jpg";
import img8 from "../Images/6-INSTALACIONES/8.png";

export const photos = [
    {
      src: img1,
      width: 3,
      height: 4
    },
    {
      src: img2,
      width: 3,
      height: 4
    },
    {
      src: img3,
      width: 4,
      height: 3
    },
    {
      src: img4,
      width: 4,
      height: 3
    },
    {
      src: img5,
      width: 4,
      height: 4
    },
    {
      src: img6,
      width: 4,
      height: 3
    },
    {
      src: img7,
      width: 4,
      height: 3
    },
    {
      src:img8,
      width: 4,
      height: 3
    }
  ];
  