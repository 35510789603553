import React, { Fragment, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import { langContext } from '../Context/langContext';

import abdominoplastia1 from '../Images/3-Cirugia-corporal/3-Contorno corporal/2-ABDOMINOPLASTIA/1-PORTADA-1400.png';
import abdominoplastia9 from '../Images/3-Cirugia-corporal/3-Contorno corporal/2-ABDOMINOPLASTIA/1-PORTADA-1400-en.png';
import abdominoplastia2 from '../Images/3-Cirugia-corporal/3-Contorno corporal/2-ABDOMINOPLASTIA/8.png'
import abdominoplastia3 from '../Images/3-Cirugia-corporal/3-Contorno corporal/2-ABDOMINOPLASTIA/CASOS-REALES/1.jpg'
import abdominoplastia4 from '../Images/3-Cirugia-corporal/3-Contorno corporal/2-ABDOMINOPLASTIA/CASOS-REALES/2.jpg'
import abdominoplastia5 from '../Images/3-Cirugia-corporal/3-Contorno corporal/2-ABDOMINOPLASTIA/CASOS-REALES/3.jpg'
import abdominoplastia6 from '../Images/3-Cirugia-corporal/3-Contorno corporal/2-ABDOMINOPLASTIA/CASOS-REALES/4.jpg'
import abdominoplastia7 from '../Images/3-Cirugia-corporal/3-Contorno corporal/2-ABDOMINOPLASTIA/CASOS-REALES/5.png'
import abdominoplastia8 from '../Images/3-Cirugia-corporal/3-Contorno corporal/2-ABDOMINOPLASTIA/CASOS-REALES/6.png'

const Lipoescultura = props => {

    const idioma = useContext(langContext).obtenerLenguaje();

    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });

    return (

        <Fragment>

            {idioma === "es-MX" ? (
                <img className="img-ma" src={abdominoplastia1} alt=""></img>
            ) : (
                <img className="img-ma" src={abdominoplastia9} alt=""></img>
            )}  

            <h4 style={{marginTop:"-80px"}}>
                <FormattedMessage
                id="titulos.1"
                defaultMessage="Descripción"
                />
            </h4>

            <div className='contenedor txt-separacion'>
                <p className='txt-ma' style={{marginTop:"60px"}}>
                    <FormattedMessage
                    id="abdominoplastia.1"
                    defaultMessage="Consiste en la eliminación del exceso de piel y grasa abdominal y la reparación de los músculos del 
                                    abdomen que presentan flacidez."
                    />
                </p>

                <p className='txt-ma'>
                    <FormattedMessage
                    id="abdominoplastia.2"
                    defaultMessage="Esta cirugía se realiza principalmente en pacientes que han tenido aumento de peso y pérdida posterior, así como en 
                                    mujeres que han tenido varios embarazos con el subsecuente exceso de piel residual y flacidez abdominal."
                    />
                </p>

                <p className='txt-ma'>
                    <FormattedMessage
                    id="abdominoplastia.3"
                    defaultMessage="La abdominoplastia consigue un abdomen más plano, más firme y una cintura más estrecha, se corrigen los defectos del 
                                    abdomen, tanto en la piel, como en la grasa y la musculatura; de esta forma se mejora su aspecto."
                    />
                </p>

                <p className='txt-ma'>
                    <FormattedMessage
                    id="abdominoplastia.4"
                    defaultMessage="Generalmente se realiza en conjunto con una lipoescultura."
                    />
                </p>

            </div>


            <div className="flexbox contenedor" style={{marginTop: "100px", marginBottom: "100px"}}>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-abdominoplastia2" src={abdominoplastia2} alt=""></img>
                </div>

                <div className='flexbox-hijo order-2'>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.2"
                        defaultMessage="¿Quiénes son candidatos?"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="abdominoplastia.5"
                        defaultMessage="Haber tenido uno o más embarazos previos."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="abdominoplastia.6"
                        defaultMessage="Haber tenido una pérdida masiva de peso."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="abdominoplastia.7"
                        defaultMessage="Tener exceso o flacidez de la piel abdominal."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="abdominoplastia.8"
                        defaultMessage="Tener un abdomen que sobresale y está fuera de proporción con el resto de su cuerpo."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="abdominoplastia.9"
                        defaultMessage="Tener los músculos abdominales separados y debilitados."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="abdominoplastia.10"
                        defaultMessage="Tener exceso de tejido graso que se concentra en su abdomen."
                        />
                    </p>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.3"
                        defaultMessage="Preparación/Anestesia"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="abdominoplastia.11"
                        defaultMessage="Cirugía con anestesia regional (bloqueo epidural y sedación)."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="abdominoplastia.12"
                        defaultMessage="Este procedimiento requiere 1 noche de hospital."
                        />
                    </p>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.4"
                        defaultMessage="Cuidados Post-Operatorios"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="abdominoplastia.13"
                        defaultMessage="Reposo durante 2 a 3 semanas."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="abdominoplastia.14"
                        defaultMessage="Regreso al ejercicio a partir de 6 a 8 semanas."
                        />
                    </p>

                </div>

            </div>


            <h4 style={{color:"#0E766E"}}>
                <FormattedMessage
                id="titulos.5"
                defaultMessage="¿Necesitas un procedimiento personalizado?"
                />
            </h4>

            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-3" href='/cita'>
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>


            <h4>
                <FormattedMessage
                id="titulos.6"
                defaultMessage="Casos reales:"
                />
            </h4>

            <div className='container row'>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={abdominoplastia3} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={abdominoplastia4} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={abdominoplastia5} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={abdominoplastia6} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={abdominoplastia7} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={abdominoplastia8} alt=""></img>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Lipoescultura;