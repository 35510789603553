//integracion de firestore en el proyecto
import { createStore, combineReducers, compose } from 'redux';
import { reactReduxFirebase, firebaseReducer } from 'react-redux-firebase';
import { reduxFirestore, firestoreReducer } from 'redux-firestore';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage'

// configurar firestore
const firebaseConfig = {
    apiKey: "AIzaSyAwnrw9xq7q7yER-oLL-eqfC8msHV29Kl4",
    authDomain: "doctor-javier.firebaseapp.com",
    projectId: "doctor-javier",
    storageBucket: "doctor-javier.appspot.com",
    messagingSenderId: "487805246268",
    appId: "1:487805246268:web:3036a7936a3f2685181c39",
    measurementId: "G-W0W0YHWK2N"
}

//configuracion de react-redux
const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true
}

// inicializar firebase
firebase.initializeApp(firebaseConfig);

// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore

// Reducers
const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer
})

// crear el anlace con compose de redux y firestore
const createStoreWithFirebase = compose(
    reactReduxFirebase(firebase, rrfConfig),
    reduxFirestore(firebase)
)(createStore);

// state inicial
const initialState = {};

//crear el store
const store = createStoreWithFirebase(rootReducer, initialState)


export default store;