import img1 from "../Images/6-INSTALACIONES/Consultorio/1.png";
import img2 from "../Images/6-INSTALACIONES/Consultorio/2.jpg";
import img3 from "../Images/6-INSTALACIONES/Consultorio/3.jpg";
import img4 from "../Images/6-INSTALACIONES/Consultorio/4.jpg";


export const photos = [
    {
      src: img1,
      width: 4,
      height: 4
    },
    {
      src: img2,
      width: 4,
      height: 3
    },
    {
      src: img3,
      width: 4,
      height: 3
    },
    {
      src: img4,
      width: 4,
      height: 3
    }

  ];
  