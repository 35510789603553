import Swal from 'sweetalert2';

const firebaseHelper = {
    /**
     * 
     * @param {nombre de la coleccion a eliminar} collection 
     * @param {conexion de firebase} firestore 
     * @param {datos a agregar} data 
     * @param {URL de redireccionamiento} url 
     * @param {conexion con funcion history} history 
     */
    agregarFormulario: (collection, firestore, data) => {
        console.log(data);
        firestore.add({
            collection: collection 
        }, data).then(() => {
            window.location.replace("/gracias")
        });
    }
}

export default firebaseHelper;