import React, { Fragment, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import { langContext } from '../Context/langContext';

import ma1 from '../Images/4-Cirugia-facial/4-RINOPLASTIA/1-PORTADA-1400.png';
import ma9 from '../Images/4-Cirugia-facial/4-RINOPLASTIA/1-PORTADA-1400-en.png';
import ma2 from '../Images/4-Cirugia-facial/4-RINOPLASTIA/13.png'
import ma3 from '../Images/4-Cirugia-facial/4-RINOPLASTIA/CASOS-REALES/1.jpg'
import ma4 from '../Images/4-Cirugia-facial/4-RINOPLASTIA/CASOS-REALES/2.jpg'
import ma5 from '../Images/4-Cirugia-facial/4-RINOPLASTIA/CASOS-REALES/3.jpg'
import ma6 from '../Images/4-Cirugia-facial/4-RINOPLASTIA/CASOS-REALES/4.jpg'
import ma7 from '../Images/4-Cirugia-facial/4-RINOPLASTIA/CASOS-REALES/5.jpg'
import ma8 from '../Images/4-Cirugia-facial/4-RINOPLASTIA/CASOS-REALES/6.jpg'



const Rinoplastia = props => {

    const idioma = useContext(langContext).obtenerLenguaje();

    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });

    return (

        <Fragment>

            {idioma === "es-MX" ? (
                <img className="img-ma" src={ma1} alt=""></img>
            ) : (
                <img className="img-ma-en" src={ma9} alt=""></img>
            )}

            <h4 style={{marginTop:"-80px"}}>
                <FormattedMessage
                id="titulos.1"
                defaultMessage="Descripción"
                />
            </h4>

            <div className='contenedor txt-separacion'>
                <p className='txt-ma' style={{marginTop:"60px"}}>
                    <FormattedMessage
                    id="rinoplastia.1"
                    defaultMessage="La Rinoplastia es la intervención encaminada a mejorar el aspecto de la nariz, ya sea para remodelar la punta, quitar 
                                    una giba o mejorar un ángulo entre la nariz y el labio superior poco armónico."
                    />
                </p>

            </div>


            <div className="flexbox contenedor" style={{marginTop: "100px", marginBottom: "100px"}}>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-ritidoplastia2" src={ma2} alt=""></img>
                </div>

                <div className='flexbox-hijo order-2'>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.3"
                        defaultMessage="Preparación/Anestesia"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="rinoplastia.2"
                        defaultMessage="Los pacientes ideales son mayores de 50 años o que presenten datos de caída del tejido facial con arrugas marcada."
                        />
                    </p>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.4"
                        defaultMessage="Cuidados Post-Operatorios"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="rinoplastia.3"
                        defaultMessage="Cirugía con anestesia local y sedación."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="rinoplastia.4"
                        defaultMessage="Procedimiento ambulatorio."
                        />
                    </p>

                </div>

            </div>


            <h4 style={{color:"#0E766E"}}>
                <FormattedMessage
                id="titulos.5"
                defaultMessage="¿Necesitas un procedimiento personalizado?"
                />
            </h4>

            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-3">
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>


            <h4>
                <FormattedMessage
                id="titulos.6"
                defaultMessage="Casos reales:"
                />
            </h4>

            <div className='container row'>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ma3} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ma4} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ma5} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ma6} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ma7} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ma8} alt=""></img>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Rinoplastia;