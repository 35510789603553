import React, { Fragment, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import { langContext } from '../Context/langContext';

import blefaroplastia1 from '../Images/4-Cirugia-facial/2-BLEFAROPLASTIA/1-PORTADA-1400.png';
import blefaroplastia5 from '../Images/4-Cirugia-facial/2-BLEFAROPLASTIA/1-PORTADA-1400-en.png';
import blefaroplastia2 from '../Images/4-Cirugia-facial/2-BLEFAROPLASTIA/11.png'
import blefaroplastia3 from '../Images/4-Cirugia-facial/2-BLEFAROPLASTIA/CASOS-REALES/1.JPG'
import blefaroplastia4 from '../Images/4-Cirugia-facial/2-BLEFAROPLASTIA/CASOS-REALES/2.jpg'

const Blefaroplastia = props => {

    const idioma = useContext(langContext).obtenerLenguaje();

    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });

    return (

        <Fragment>

            {idioma === "es-MX" ? (
                <img className="img-ma" src={blefaroplastia1} alt=""></img>
            ) : (
                <img className="img-ma" src={blefaroplastia5} alt=""></img>
            )}

            <h4 style={{marginTop:"-80px"}}>
                <FormattedMessage
                id="titulos.1"
                defaultMessage="Descripción"
                />
            </h4>

            <div className='contenedor txt-separacion'>
                <p className='txt-ma' style={{marginTop:"60px"}}>
                    <FormattedMessage
                    id="blefaroplastia.1"
                    defaultMessage="La blefaroplastia es una cirugía estética que tiene como objetivo corregir la apariencia de los párpados y el área 
                                    periorbitaria; Se retira el exceso de piel en párpado superior y quita exceso de grasa en párpado inferior acomodando 
                                    la grasa para mejorar el aspecto a nivel periorbitario."
                    />
                </p>

                <p className='txt-ma'>
                    <FormattedMessage
                    id="blefaroplastia.2"
                    defaultMessage="Por lo general estos problemas son cambios propios de la edad, con el paso del tiempo y la fuerza de gravedad actúan 
                                    sobre el rostro, generando un aspecto antiestético y envejecido."
                    />
                </p>

            </div>


            <div className="flexbox contenedor" style={{marginTop: "100px", marginBottom: "100px"}}>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-ma2" src={blefaroplastia2} alt=""></img>
                </div>

                <div className='flexbox-hijo order-2'>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.3"
                        defaultMessage="Preparación/Anestesia"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="blefaroplastia.3"
                        defaultMessage="Cirugía con anestesia local y sedación."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="blefaroplastia.4"
                        defaultMessage="Procedimiento ambulatorio."
                        />
                    </p>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.4"
                        defaultMessage="Cuidados Post-Operatorios"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="blefaroplastia.5"
                        defaultMessage="Reposo de entre 7 a 10 días."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="blefaroplastia.6"
                        defaultMessage="Evitar el sol."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="blefaroplastia.7"
                        defaultMessage="Regreso a la vida normal a partir de 10 días."
                        />
                    </p>

                </div>

            </div>


            <h4 style={{color:"#0E766E"}}>
                <FormattedMessage
                id="titulos.5"
                defaultMessage="¿Necesitas un procedimiento personalizado?"
                />
            </h4>

            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-3" href='/cita'>
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>


            <h4>
                <FormattedMessage
                id="titulos.6"
                defaultMessage="Casos reales:"
                />
            </h4>

            <div className='container row'>

                <div className='col s6 m6'>
                    <div className='materialboxed img-galeria4 img-espacio'>
                        <img className='imagenes_galeria2' src={blefaroplastia3} alt=""></img>
                    </div>
                </div>

                <div className='col s6 m6'>
                    <div className='materialboxed img-galeria4 img-espacio'>
                        <img className='imagenes_galeria2' src={blefaroplastia4} alt=""></img>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Blefaroplastia;