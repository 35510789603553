import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import bichectomia1 from '../Images/4-Cirugia-facial/1-BICHECTOMIA/1-PORTADA-1400.png';
import bichectomia2 from '../Images/4-Cirugia-facial/1-BICHECTOMIA/1.png'

const Bichectomia = props => {

    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });

    return (

        <Fragment>

            <img className="img-ma" src={bichectomia1} alt=""></img>

            <h4 style={{marginTop:"-80px"}}>
                <FormattedMessage
                id="titulos.1"
                defaultMessage="Descripción"
                />
            </h4>

            <div className='contenedor txt-separacion'>
                <p className='txt-ma' style={{marginTop:"60px"}}>
                    <FormattedMessage
                    id="bichectomia.1"
                    defaultMessage="La Bichectomia es un procedimiento menor que se realiza con la finalidad de retirar las bolsas de grasa 
                                    situadas en las mejillas, llamadas bolsas de Bichat."
                    />
                </p>

                <p className='txt-ma'>
                    <FormattedMessage
                    id="bichectomia.2"
                    defaultMessage="Obteniendo como resultado un aspecto más afilado, estético y armónico al rostro."
                    />
                </p>

            </div>


            <div className="flexbox contenedor" style={{marginTop: "100px", marginBottom: "100px"}}>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-ma2" src={bichectomia2} alt=""></img>
                </div>

                <div className='flexbox-hijo order-2'>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.3"
                        defaultMessage="Preparación/Anestesia"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="bichectomia.3"
                        defaultMessage="Cirugía con anestesia local."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="bichectomia.4"
                        defaultMessage="Procedimiento ambulatorio."
                        />
                    </p>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.4"
                        defaultMessage="Cuidados Post-Operatorios"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="bichectomia.5"
                        defaultMessage="Reposo relativo por una semana."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="bichectomia.6"
                        defaultMessage="Evitar esfuerzos."
                        />
                    </p>

                </div>

            </div>


            <h4 style={{color:"#0E766E"}}>
                <FormattedMessage
                id="titulos.5"
                defaultMessage="¿Necesitas un procedimiento personalizado?"
                />
            </h4>

            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-3" href='/cita'>
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>

        </Fragment>
    )
}

export default Bichectomia;