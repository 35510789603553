import React, { Fragment, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import { langContext } from '../Context/langContext';

import ig1 from '../Images/4-Cirugia-facial/6-INFILTRACIÓN-DE-GRASA-EN-CARA/1-PORTADA-1400.png';
import ig8 from '../Images/4-Cirugia-facial/6-INFILTRACIÓN-DE-GRASA-EN-CARA/1-PORTADA-1400-en.png';
import ig2 from '../Images/4-Cirugia-facial/6-INFILTRACIÓN-DE-GRASA-EN-CARA/16.png'
import ig3 from '../Images/4-Cirugia-facial/6-INFILTRACIÓN-DE-GRASA-EN-CARA/CASOS-REALES/1.JPG'
import ig4 from '../Images/4-Cirugia-facial/6-INFILTRACIÓN-DE-GRASA-EN-CARA/CASOS-REALES/2.jpg'
import ig5 from '../Images/4-Cirugia-facial/6-INFILTRACIÓN-DE-GRASA-EN-CARA/CASOS-REALES/3.jpg'
import ig6 from '../Images/4-Cirugia-facial/6-INFILTRACIÓN-DE-GRASA-EN-CARA/CASOS-REALES/4.jpg'
import ig7 from '../Images/4-Cirugia-facial/6-INFILTRACIÓN-DE-GRASA-EN-CARA/CASOS-REALES/5.jpg'


const Infiltracion_de_Grasa = props => {

    const idioma = useContext(langContext).obtenerLenguaje();

    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });

    return (

        <Fragment>

            {idioma === "es-MX" ? (
                <img className="img-ma" src={ig1} alt=""></img>
            ) : (
                <img className="img-ma" src={ig8} alt=""></img>
            )}

            <h4 style={{marginTop:"-80px"}}>
                <FormattedMessage
                id="titulos.1"
                defaultMessage="Descripción"
                />
            </h4>

            <div className='contenedor txt-separacion efecto-lista'>

                <p className='txt-ma' style={{marginTop:"60px", marginBottom:"30px"}}>
                    <FormattedMessage
                    id="ig.1"
                    defaultMessage="La infiltración de grasa a nivel facial es una herramienta especialmente útil para la corrección de los defectos 
                                    de volumen, causados por la edad o por secuelas de otras enfermedades."
                    />
                </p>

                <p className='txt-ma'>
                    <FormattedMessage
                    id="ig.2"
                    defaultMessage="Puede ser aplicado en:"
                    />
                </p>

                <div style={{textAlign:"left"}}>

                    <p className='txt-lista' style={{marginTop:"-15px"}}>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="ig.3"
                        defaultMessage="Labios: volumen, contorno y comisura."
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-15px"}}>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="ig.4"
                        defaultMessage="Pómulos: volumen facial."
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-15px"}}>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="ig.5"
                        defaultMessage="Alrededor de la boca: arrugas labiales o peribucales."
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-15px"}}>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="ig.6"
                        defaultMessage="Surcos nasogenianos: líneas de expresión de ambos lados de la nariz hacia la boca."
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-15px"}}>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="ig.7"
                        defaultMessage="Patas de gallo: arrugas perioculares externas."
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-15px"}}>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="ig.8"
                        defaultMessage="Entrecejo y frente."
                        />
                    </p>
                
                </div>

            </div>


            <div className="flexbox contenedor" style={{marginTop: "100px", marginBottom: "100px"}}>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-rf2" src={ig2} alt=""></img>
                </div>

                <div className='flexbox-hijo order-2'>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.7"
                        defaultMessage="Más información"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="ig.9"
                        defaultMessage="La duración va variar según la edad, la zona de aplicación, la piel del paciente y su estilo de vida
                                        del paciente (alimentación, tabaco, deporte, etc) el relleno facial suele durar entre 9 y 12 meses."
                        />
                    </p>

                </div>

            </div>


            <h4 style={{color:"#0E766E"}}>
                <FormattedMessage
                id="titulos.5"
                defaultMessage="¿Necesitas un procedimiento personalizado?"
                />
            </h4>

            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-3" href='/cita'>
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>


            <h4>
                <FormattedMessage
                id="titulos.6"
                defaultMessage="Casos reales:"
                />
            </h4>

            <div className='container row'>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria3' src={ig3} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria3' src={ig4} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria3' src={ig5} alt=""></img>
                    </div>
                </div>

                <div className='col s6 m6'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria3' src={ig6} alt=""></img>
                    </div>
                </div>

                <div className='col s6 m6'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria3' src={ig7} alt=""></img>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Infiltracion_de_Grasa;