import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import sc1 from '../Images/5-SIN-CIRUGÍA/1-PORTADA-1400.png';
import sc2 from '../Images/ICONOS/16.png';
import sc3 from '../Images/ICONOS/21.png';



const Cirugia_Facial = props => {

    return (
        <Fragment>

            <img className="img-cc" src={sc1} alt=""></img>

            <h1 style={{marginBottom: "100px", marginTop:"-120px"}}>
                <FormattedMessage
                id="header.5"
                defaultMessage="Sin Cirugía"
                />
            </h1>

            <div className="container padre_1" style={{marginBottom:"200px"}}>

            <a href='/botox' style={{color:"black"}}>
                 <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={sc2} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="sc.1"
                            defaultMessage="Toxina Botulínica"
                            />
                        </p>
                        <p style={{fontSize:"22px", marginTop:"-10px"}}>
                            <FormattedMessage
                            id="sc.2"
                            defaultMessage="(BOTOX)"
                            />
                        </p>
                    </div>
                </a>

                <a href='/rellenos-faciales' style={{color:"black"}}>
                    <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={sc3} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="sc.3"
                            defaultMessage="Rellenos Faciales"
                            />
                        </p>
                    </div>
                </a>
        
            </div>

        </Fragment>
    )
}

export default Cirugia_Facial;