import React, { Fragment, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import { langContext } from '../Context/langContext';

import gracias1 from '../Images/7-GRACIAS/fb.png';
import gracias2 from '../Images/7-GRACIAS/ig.png';
import gracias3 from '../Images/7-GRACIAS/wa.png';

const Gracias = props => {

    return (

        <Fragment>

            <div className='fondo_1'>

                <div className='alinear-izquierda'>
                    <p className='titulo-gracias'>
                        <FormattedMessage
                            id="gracias.1"
                            defaultMessage="Envío exitoso"
                        />
                    </p>

                    <p className='texto-gracias'>
                        <FormattedMessage
                            id="gracias.2"
                            defaultMessage="Gracias por completar el formulario con tus datos."
                        />
                    </p>
                    <p className='texto-gracias'>
                        <FormattedMessage
                            id="gracias.3"
                            defaultMessage="En breve, mi equipo se pondrá en contacto contigo."
                        />
                    </p>

                    <div className='imagenes-gracias-padre'>
                        <a href="https://www.facebook.com/drjavierguerrero">
                            <div className='imagenes-gracias'>
                                <img style={{width:"100%"}} src={gracias1} alt=""></img>
                            </div>
                        </a>
                        <a href="https://www.instagram.com/dr_javier_guerrero/" style={{margin:"0px 3%"}}>
                            <div className='imagenes-gracias'>
                                <img style={{width:"100%"}} src={gracias2} alt=""></img>
                            </div>
                        </a>
                        <a href="https://wa.me/523312553538?text=¡Hola buen día! Me gustaría agendar una cita">
                            <div className='imagenes-gracias'>
                                <img style={{width:"100%"}} src={gracias3} alt=""></img>
                            </div>
                        </a>
                    </div>

                </div>


            </div>

        </Fragment>
    )
}

export default Gracias;