import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import mamas1 from '../Images/ICONOS/3.png';
import mamas2 from '../Images/ICONOS/8.png';
import mamas3 from '../Images/ICONOS/9.png';


const Mamas = props => {

    return (
        <Fragment>

            <h1 style={{marginBottom: "100px", color:"#0E766E"}}>
                <FormattedMessage
                id="mamas.1"
                defaultMessage="Mamas"
                />
            </h1>

            <div className="container padre_1" style={{marginBottom:"200px"}}>

                <a href='/mamoplastia-de-aumento' style={{color:"black"}}>
                    <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={mamas1} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="mamas.2"
                            defaultMessage="Mamoplastía"
                            />
                        </p>

                        <p style={{fontSize:"22px", marginTop:"-10px"}}>
                            <FormattedMessage
                            id="mamas.2.1"
                            defaultMessage="de Aumento"
                            />
                        </p>
                    </div>
                </a>

            <a href='/mastopexia' style={{color:"black"}}>
                <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                    <img style={{width:"35%"}} src={mamas2} alt=""></img>
                    <p style={{fontSize:"22px", marginTop:"5px"}}>
                        <FormattedMessage
                        id="mamas.3"
                        defaultMessage="Mastopexia"
                        />
                    </p>
                    <p style={{fontSize:"15px", marginTop:"-10px"}}>
                        <FormattedMessage
                        id="mamas.3.1"
                        defaultMessage="(Levantamiento mamario)"
                        />
                    </p>
                </div>
            </a>
                
            
            <a href='/mamoplastia-de-reduccion' style={{color:"black"}}>
                <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                    <img style={{width:"35%"}} src={mamas3} alt=""></img>
                    <p style={{fontSize:"22px", marginTop:"5px"}}>
                        <FormattedMessage
                        id="mamas.4"
                        defaultMessage="Mamoplastía"
                        />
                    </p>
                    <p style={{fontSize:"22px", marginTop:"-10px"}}>
                        <FormattedMessage
                        id="mamas.4.1"
                        defaultMessage="de Reducción"
                        />
                    </p>
                </div>
            </a>
        
            </div>

        </Fragment>
    )
}

export default Mamas;