import React, {Fragment}  from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import store from '../store';
import { Provider } from 'react-redux';

// *** componentes ***  //
import NavBar from '../Components/Header';
import Footer from '../Components/Footer';
import Boton from '../Components/Boton';
//import Redes from './Components/Redes';

// *** vistas ***  //
import Index from '../Views/Index';
import Redireccion from '../Views/Redireccion';
import Dr_Javier from '../Views/Dr-Javier';
import Instalaciones from '../Views/Instalaciones';
import Cita from '../Views/Cita';
import Cirugia_Corporal from '../Views/Cirugia_Corporal';
import Mamas from '../Views/Mamas';
import Mamoplastia_de_Aumento from '../Views/Mamoplastia_de_Aumento';
import Mastopexia from '../Views/Mastopexia';
import Mamoplastia_de_Reduccion from '../Views/Mamoplastia_de_Reduccion';
import Contorno_Corporal from '../Views/Contorno_Corporal';
import Lipoescultura from '../Views/Lipoescultura';
import Abdominoplastia from '../Views/Abdominoplastia';
import Mommy_Make_Over from '../Views/Mommy_Make_Over';
import Gluteoplastia from '../Views/Gluteoplastia';
import Cirugia_Facial from '../Views/Cirugia_Facial';
import Sin_Cirugia from '../Views/Sin_Cirugia';
import Blefaroplastia from '../Views/Blefaroplastia';
import Ritidoplastia from '../Views/Ritidoplastia';
import Rinoplastia from '../Views/Rinoplastia';
import Otoplastia from '../Views/Otoplastia';
import Botox from '../Views/Botox';
import Infiltracion_de_Grasa from '../Views/Infiltracion_de_Grasa';
import Bichectomia from '../Views/Bichectomia';
import Rellenos_Faciales from '../Views/Rellenos_Faciales';
import Gracias from '../Views/Gracias';

// *** administrador *** //
import login from '../Views/administrador/Login';
//usuarios
import usuarios from '../Views/administrador/Usuarios/Usuarios';
import nuevoUsuario from '../Views/administrador/Usuarios/NuevoUsuario';
import editarUsuario from '../Views/administrador/Usuarios/EditarUsuario';
//categorias
import categorias from '../Views/administrador/Categorias/Categorias';
import nuevoCategoria from '../Views/administrador/Categorias/NuevaCategoria';
import editarCategoria from '../Views/administrador/Categorias/EditarCategoria';
//productos
import productos from '../Views/administrador/Producto/Productos';
import nuevoProducto from '../Views/administrador/Producto/NuevoProducto';
import editarProducto from '../Views/administrador/Producto/EditarProducto';
//imagenes
import imagenesCategorias from '../Views/administrador/Imagenes/Categorias';
import imagenesProductos from '../Views/administrador/Imagenes/Imagenes';
import nuevaImagenProducto from '../Views/administrador/Imagenes/NuevaImagen';
import editarImagenProducto from '../Views/administrador/Imagenes/EditarImagen';


const Router = () =>(
    <Provider store={store}>

        <BrowserRouter>
            <Fragment>
                {window.location.pathname.split('/')[1] === 'admin' ? (
                    <span></span>
                ) : (
                    <NavBar />
                )}
                <Boton />
                
                {/* vistas */}
                <Route exact path = "/" component = {Index} />
                <Route  path = "/dr-javier" component = {Dr_Javier} />
                <Route  path = "/instalaciones" component = {Instalaciones} />
                <Route  path = "/cita" component = {Cita} />
                <Route  path = "/cirugia-corporal" component = {Cirugia_Corporal} />
                <Route  path = "/mamas" component = {Mamas} />
                <Route  path = "/mamoplastia-de-aumento" component = {Mamoplastia_de_Aumento} />
                <Route  path = "/mastopexia" component = {Mastopexia} />
                <Route  path = "/mamoplastia-de-reduccion" component = {Mamoplastia_de_Reduccion} />
                <Route  path = "/contorno-corporal" component = {Contorno_Corporal} />
                <Route  path = "/lipoescultura" component = {Lipoescultura} />
                <Route  path = "/abdominoplastia" component = {Abdominoplastia} />
                <Route  path = "/mommy-make-over" component = {Mommy_Make_Over} />
                <Route  path = "/gluteoplastia" component = {Gluteoplastia} />
                <Route  path = "/cirugia-facial" component = {Cirugia_Facial} />
                <Route  path = "/sin-cirugia" component = {Sin_Cirugia} />
                <Route  path = "/blefaroplastia" component = {Blefaroplastia} />
                <Route  path = "/ritidoplastia" component = {Ritidoplastia} />
                <Route  path = "/rinoplastia" component = {Rinoplastia} />
                <Route  path = "/otoplastia" component = {Otoplastia} />
                <Route  path = "/botox" component = {Botox} />
                <Route  path = "/infiltracion-de-grasa" component = {Infiltracion_de_Grasa} />
                <Route  path = "/bichectomia" component = {Bichectomia} />
                <Route  path = "/rellenos-faciales" component = {Rellenos_Faciales} />
                <Route  path = "/dr-javier-guerrero" component = {Redireccion} />
                <Route  path = "/gracias" component = {Gracias} />

                {/* fin - vistas */}
                {/* administrador */}
                <   Route  path = "/admin/login" component = {login} />
                        {/* usuario */}
                        <Route  path = "/admin/usuarios" component = {usuarios} />
                        <Route  path = "/admin/nuevo-usuario" component = {nuevoUsuario} />
                        <Route  path = "/admin/editar-usuario/:id" component = {editarUsuario} />
                        {/* fin - usuario */}
                        {/* categorias */}
                        <Route  path = "/admin/categorias" component = {categorias} />
                        <Route  path = "/admin/nueva-categoria" component = {nuevoCategoria} />
                        <Route  path = "/admin/editar-categoria/:id" component = {editarCategoria} />
                        {/* fin - categorias */}
                        {/* productos */}
                        <Route  path = "/admin/productos/:id" component = {productos} />
                        <Route  path = "/admin/nuevo-producto/:id" component = {nuevoProducto} />
                        <Route  path = "/admin/editar-producto/:id" component = {editarProducto} />
                        {/* fin - productos */}
                        {/* imagenes */}
                        <Route  path = "/admin/imagenes-categorias" component = {imagenesCategorias} />
                        <Route  path = "/admin/imagenes-productos/:id" component = {imagenesProductos} />
                        <Route  path = "/admin/nueva-imagen-producto/:id" component = {nuevaImagenProducto} />
                        <Route  path = "/admin/editar-imagen-producto/:id" component = {editarImagenProducto} />
                        {/* fin - imagenes */}
                    {/* fin - administrador */}
                {window.location.pathname.split('/')[1] === 'admin' ? (
                    <span></span>
                ) : (
                    <Footer />
                )}
            </Fragment>
        </BrowserRouter>
    </Provider>
);

export default Router;