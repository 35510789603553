import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import cc1 from '../Images/3-Cirugia-corporal/1-PORTADA-1400.png';
import cc2 from '../Images/ICONOS/3.png';
import cc3 from '../Images/ICONOS/1.png';
import cc4 from '../Images/ICONOS/4.png';


const Cirugia_Corporal = props => {

    return (
        <Fragment>

            <img className="img-cc" src={cc1} alt=""></img>

            <h1 style={{marginBottom: "100px", marginTop:"-120px"}}>
                <FormattedMessage
                id="cc.1"
                defaultMessage="Cirugía Corporal"
                />
            </h1>

            <div className="container padre_1" style={{marginBottom:"200px"}}>

                <a href='/mamas' style={{color:"black"}}>
                    <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={cc2} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="cc.2"
                            defaultMessage="Mamas"
                            />
                        </p>
                    </div>
                </a>
               
            
                <a href='/contorno-corporal' style={{color:"black"}}>
                    <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={cc3} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="cc.3"
                            defaultMessage="Contorno Corporal"
                            />
                        </p>
                    </div>
                </a>
                
                <a href='/gluteoplastia' style={{color:"black"}}>
                     <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={cc4} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="cc.4"
                            defaultMessage="Glúteos y Cadera"
                            />
                        </p>
                    </div>
                </a>  
        
            </div>

        </Fragment>
    )
}

export default Cirugia_Corporal;