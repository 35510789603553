import React, { useEffect } from 'react';

const Redireccion = () => {
    useEffect(() => {
        window.location.replace("/");
        // window.location.reload();
    })

    return(
        <span></span>
    )
}

export default Redireccion;