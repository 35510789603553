import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import dj1 from '../Images/2-DR-JAVIER-GUERRERO/1-DR-JAVIER.png'
import dj2 from'../Images/ICONOS/5.png';
import dj3 from'../Images/ICONOS/6.png';
import dj4 from'../Images/ICONOS/7.png';
import dj5 from '../Images/2-DR-JAVIER-GUERRERO/GALERIA-EQUIPO/1.jpg'
import dj6 from '../Images/2-DR-JAVIER-GUERRERO/GALERIA-EQUIPO/2.jpg'
import dj7 from '../Images/2-DR-JAVIER-GUERRERO/GALERIA-EQUIPO/3.JPG'
import dj8 from '../Images/2-DR-JAVIER-GUERRERO/GALERIA-EQUIPO/4.jpg'
import dj9 from '../Images/2-DR-JAVIER-GUERRERO/GALERIA-EQUIPO/5.jpg'


const Dr_Javier = props => {

    /* Se inicia el carrusel de imagenes */ 
    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });
    

    return (
        <Fragment>
            
            <div className="flexbox contenedor" style={{marginTop: "100px"}}>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-dj1" src={dj1} alt=""></img>
                </div>

                <div className='flexbox-hijo order-2'>

                    <h1 style={{color:"black"}}>Dr. Javier Guerrero</h1>

                    <div className="linea-hor"></div>

                    <h2 style={{color:"#0E766E"}}>
                        <FormattedMessage
                        id="index.6"
                        defaultMessage="Cirujano Plástico Certificado"
                        />
                    </h2>

                    <p className='txt-dj texto'>
                        <FormattedMessage
                        id="index.7"
                        defaultMessage="Realizó su especialidad médica en Cirugía Plástica en el Instituto Jalisciense de Cirugía 
                                        Reconstructiva Dr. José Guerrero Santos, capacitándose constantemente con algunos de los mejores y 
                                        más reconocidos cirujanos plásticos del país."
                        />
                    </p>

                    <br></br>

                    <p className='txt-dj texto' style={{marginBottom:"50px"}}>
                        <FormattedMessage
                        id="index.8"
                        defaultMessage="El servicio que ofrece es totalmente personalizado, ajustándose a sus necesidades 
                                        y tipo de cuerpo, con el fin de obtener los mejores resultados."
                        />
                    </p>

                    <div className='center-btn'>
                        <a class="waves-effect waves-light btn btn-cita" href='/cita'>
                            <FormattedMessage
                            id="index.btn-2"
                            defaultMessage="Agenda una cita"
                            />
                        </a>    
                    </div>

                </div>

            </div>


            <div className='center-image efecto-lista'>

                <img className="img-dj2" src={dj2} alt=""></img>

                <p className='texto-nosotros-titulo titulo'>
                    <FormattedMessage
                    id="nosotros.1"
                    defaultMessage="Formación:"
                    />
                </p>

                <div style={{textAlign:"left"}}>

                    <p className='texto-nosotros contenedor-movil texto'>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="nosotros.2"
                        defaultMessage="Medicina General en el Centro Universitario de Ciencias de la Salud de la Universidad de Guadalajara."
                        />
                    </p>

                    <p className='texto-nosotros contenedor-movil texto'>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="nosotros.3"
                        defaultMessage="Cirugía General en el Hospital Civil Fray Antonio Alcalde."
                        />
                    </p>

                    <p className='texto-nosotros contenedor-movil texto'>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="nosotros.4"
                        defaultMessage="Cirugía Plástica en el Instituto Jalisciense de Cirugía Reconstructiva Dr. José Guerrero Santos."
                        />
                    </p>

                </div>

                <img className="img-dj3" src={dj3} alt=""></img>

                <p className='texto-nosotros-titulo titulo'>
                    <FormattedMessage
                    id="nosotros.5"
                    defaultMessage="Adiestramientos:"
                    />
                </p>

                <div style={{textAlign:"left"}}>

                    <p className='texto-nosotros contenedor-movil texto'>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="nosotros.6"
                        defaultMessage="Cirugía facial avanzada con el Dr. Héctor González Miramontes."
                        />
                    </p>

                    <p className='texto-nosotros contenedor-movil texto'>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="nosotros.7"
                        defaultMessage="Feminización facial con el Dr. Lázaro Cárdenas."
                        />
                    </p>

                </div>

                <img className="img-dj4" src={dj4} alt=""></img>

                <p className='texto-nosotros-titulo titulo' style={{marginTop:"-5px"}}>
                    <FormattedMessage
                    id="nosotros.8"
                    defaultMessage="Expertise:"
                    />
                </p>

                <div style={{textAlign:"left"}}>

                    <p className='texto-nosotros contenedor-movil texto' style={{marginLeft: "5%",marginRight: "5%"}}>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="nosotros.9"
                        defaultMessage="Cirugía corporal de alta definición usando los mejores aparatos"
                        />
                    </p>
                    <p className='texto-nosotros contenedor-movil texto' style={{marginLeft: "5%",marginRight: "5%", marginTop: "-20px"}}>
                        <FormattedMessage
                        id="nosotros.9.1"
                        defaultMessage="de tecnología para lipoescultura como Microaire, Renuvion-J Plasma y Vaser."
                        />
                    </p>

                </div>
                
            </div>


            <p className='titulo' style={{textAlign:"center", fontSize:"44px", color:"#0E766E", marginTop:"100px"}}>
                <FormattedMessage
                id="nosotros.10"
                defaultMessage="Equipo de Trabajo"
                />
            </p>


            <div className='container row'>

                <div className='col s12'>
                    <img className='materialboxed img-idx14' src={dj5} alt=""></img>
                </div>

                <div className='col s6 m3'>
                    <div className='materialboxed img-galeria2 img-espacio'>
                        <img className='img-idx15' src={dj6} alt=""></img>
                    </div>
                </div>

                <div className='col s6 m3'>
                    <div className='materialboxed img-galeria2 img-espacio'>
                        <img className='img-dj7' src={dj7} alt=""></img>
                    </div>
                </div>

                <div className='col s6 m3'>
                    <div className='materialboxed img-galeria2 img-espacio'>
                        <img className='img-idx17' src={dj8} alt=""></img>
                    </div>
                </div>

                <div className='col s6 m3'>
                    <div className='materialboxed img-galeria2 img-espacio'>
                        <img className='img-idx18' src={dj9} alt=""></img>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Dr_Javier;