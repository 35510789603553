import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { firestoreConnect } from 'react-redux-firebase';
// import Swal from 'sweetalert2';

import firebaseHelper from '../Helpers/Firebase';
import Mapa from '../Components/Mapa';


const Cita = props => {

    const [state, setState] = useState({
        asunto:"Contacto",
        captcha:true,
        email:"",
        estado:false,
        fecha:new Date(),
        nombre:"",
        telefono:"",
        mensaje:""
    });

    const leerDato = e => {
        e.preventDefault();
        setState({...state, [e.target.name]: e.target.value})
    }

    const enviarMensaje = e => {
        e.preventDefault();
        const { firestore } = props;
        // const formualrio = document.getElementById("formulario");
        // formualrio.reset();
        firebaseHelper.agregarFormulario("mensaje", firestore, state);
    }

    return (
        <Fragment>
            
            <h1 style={{color:"#127870"}}>
                <FormattedMessage
                id="cita.1"
                defaultMessage="Agenda tu cita"
                />
            </h1>

            <p className='texto' style={{textAlign:"center", fontSize:"18px"}}>
                <FormattedMessage
                id="cita.2"
                defaultMessage="Simplemente llama, manda un WhatsApp para agendar tu cita o llena el formulario y nosotros nos pondremos 
                                en contacto contigo."
                />
            </p>


            <div style={{display:"flex", alignItems:"center", alignContent:"center", justifyContent:"space-evenly"}}>

                <div>
                    <p className="md-titulo-tel-1-c titulo">
                        <FormattedMessage
                        id="index.17"
                        defaultMessage="Teléfono de"
                        />
                    </p>
                    <p className="md-titulo-tel-2-c titulo">
                        <FormattedMessage
                        id="index.17.1"
                        defaultMessage="consultorio:"
                        />
                    </p>

                    <p className="md-direccion-1-c texto">33 1255 3538</p>

                </div>

                <div>

                    <p className='separacion-mapa-2-c titulo'>
                        <FormattedMessage
                        id="index.16"
                        defaultMessage="Dirección:"
                        />
                    </p>

                    <p className="md-direccion-1-c texto">Av Naciones Unidas 7972,</p>
                    <p className="md-direccion-2-c texto">Fracc. Villa Verona, 45129</p>
                    <p className="md-direccion-2-c texto">Zapopan, Jal.</p>

                </div>

            </div>


            <div className='row contenedor' style={{marginTop:"60px"}}>

                <div className='col s12 m8' style={{padding:"0px"}}>
                    <Mapa/>
                </div>

                <div className='col s12 m4 sombra-4' style={{height:"500px", padding:"0 5%"}}>

                    <h4 style={{textAlign:"left"}}>
                        <FormattedMessage
                        id="formulario.1"
                        defaultMessage="Formulario"
                        />
                    </h4>

                    <form id="formulario" onSubmit={enviarMensaje}>
                        <div class="input-field">
                            <input id="nombre" name='nombre' type="text" class="validate" onChange={leerDato} required
                            style={{border: "1px solid #0E766E"}} />
                            <label for="nombre">Nombre</label>
                        </div>

                        <div class="input-field">
                            <input id="telefono" name='telefono' type="text" class="validate" onChange={leerDato} required
                            style={{border: "1px solid #0E766E"}} />
                            <label for="telefono">Teléfono</label>
                        </div>

                        <div class="input-field">
                            <input id="email" name='email' type="email" class="validate" onChange={leerDato} required
                            style={{border: "1px solid #0E766E"}} />
                            <label for="email">E-mail</label>
                        </div>

                        <div class="input-field">
                            <textarea id="textarea1"  name="mensaje" class="materialize-textarea form_tetxtarea" required
                                style={{border: "1px solid #0E766E"}} onChange={leerDato}>  
                            </textarea>
                            <label for="textarea1">
                            <FormattedMessage
                                id="formulario.6"
                                defaultMessage="Mensaje"
                                />
                            </label>
                        </div>

                        <div className='center-btn-enviar'>
                            <button type='submit' style={{backgroundColor:"transparent", border:"none"}}>
                                <p class="waves-effect waves-light btn btn-enviar" style={{marginTop:"0px"}}>
                                    <FormattedMessage
                                    id="formulario-btn"
                                    defaultMessage="Enviar mensaje"
                                    />
                                </p>    
                            </button>
                        </div>
                    </form>

                </div>

            </div>

        </Fragment>
    )
}

export default firestoreConnect() (Cita);