import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import Mapa from './Mapa'



const MapaDatos = props => {
    
    return (
        <Fragment>
            
            <div className='row contenedor'>

                <div className='col s12 m8' style={{padding:"0px"}}>
                    <Mapa/>
                </div>

                <div className='col s12 m4 sombra-4' style={{height:"500px"}}>

                    <p className='separacion-mapa titulo'>
                        <FormattedMessage
                        id="index.16"
                        defaultMessage="Dirección:"
                        />
                    </p>

                    <p className="md-direccion-1 texto">Av Naciones Unidas 7972,</p>
                    <p className="md-direccion-2 texto">Fracc. Villa Verona, 45129</p>
                    <p className="md-direccion-2 texto">Zapopan, Jal.</p>

                    <p className="md-titulo-tel-1 titulo">
                        <FormattedMessage
                        id="index.17"
                        defaultMessage="Teléfono de"
                        />
                    </p>
                    <p className="md-titulo-tel-2 titulo">
                        <FormattedMessage
                        id="index.17.1"
                        defaultMessage="consultorio:"
                        />
                    </p>

                    <p className="md-direccion-1 texto">33 1255 3538</p>

                </div>

            </div>

        </Fragment>
    )
}

export default MapaDatos;
