import React, { Fragment, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import { langContext } from '../Context/langContext';

import mastopexia1 from '../Images/3-Cirugia-corporal/2-mamas/MASTOPEXIA/1-PORTADA-1400.png';
import mastopexia9 from '../Images/3-Cirugia-corporal/2-mamas/MASTOPEXIA/1-PORTADA-1400-en.png';
import mastopexia2 from '../Images/3-Cirugia-corporal/2-mamas/MASTOPEXIA/5.png'
import mastopexia3 from '../Images/3-Cirugia-corporal/2-mamas/MASTOPEXIA/CASOS-REALES/1.jpg'
import mastopexia4 from '../Images/3-Cirugia-corporal/2-mamas/MASTOPEXIA/CASOS-REALES/2.jpg'
import mastopexia5 from '../Images/3-Cirugia-corporal/2-mamas/MASTOPEXIA/CASOS-REALES/3.jpg'
import mastopexia6 from '../Images/3-Cirugia-corporal/2-mamas/MASTOPEXIA/CASOS-REALES/4.png'
import mastopexia7 from '../Images/3-Cirugia-corporal/2-mamas/MASTOPEXIA/CASOS-REALES/5.png'
import mastopexia8 from '../Images/3-Cirugia-corporal/2-mamas/MASTOPEXIA/CASOS-REALES/6.png'


const Mastopexia = props => {

    const idioma = useContext(langContext).obtenerLenguaje();

    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });

    return (

        <Fragment>

            {idioma === "es-MX" ? (
                <img className="img-ma" src={mastopexia1} alt=""></img>
            ) : (
                <img className="img-ma" src={mastopexia9} alt=""></img>
            )}

            <h4 style={{marginTop:"-80px"}}>
                <FormattedMessage
                id="titulos.1"
                defaultMessage="Descripción"
                />
            </h4>

            <div className='contenedor txt-separacion efecto-lista'>

                <p className='txt-ma' style={{marginTop:"60px"}}>
                    <FormattedMessage
                    id="mastopexia.1"
                    defaultMessage="La Cirugía de levantamiento de las mamas (Mastopexia) elimina el exceso de piel que se ha acumulado 
                                    alrededor del pecho durante el embarazo o las variaciones de peso. Puede combinarse la colocación de un 
                                    implante mamario con este procedimiento para lograr el resultado deseado."
                    />
                </p>

                <p className='txt-ma'>
                    <FormattedMessage
                    id="mastopexia.2"
                    defaultMessage="Puede ser:"
                    />
                </p>

                <div style={{textAlign:"left"}}>

                    <p className='txt-lista' style={{marginTop:"-10px"}}>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="mastopexia.3"
                        defaultMessage="Incisión periareolar"
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-10px"}}>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="mastopexia.4"
                        defaultMessage="Incisión vertical"
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-10px"}}>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="mastopexia.5"
                        defaultMessage="Incisión en “T” invertida o ancla"
                        />
                    </p>

                </div>
                
            </div>


            <div className="flexbox contenedor" style={{marginTop: "100px", marginBottom: "100px"}}>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-ma2" src={mastopexia2} alt=""></img>
                </div>

                <div className='flexbox-hijo order-2'>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.2"
                        defaultMessage="¿Quiénes son candidatos?"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="mastopexia.6"
                        defaultMessage="Pacientes que con el paso del tiempo o después de la lactancia su busto ha caído. Esta cirugía 
                                        consiste en llevar el busto a su posición normal y darle firmeza."
                        />
                    </p>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.3"
                        defaultMessage="Preparación/Anestesia"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="mastopexia.7"
                        defaultMessage="Cirugía con anestesia bloqueo epidural y sedación."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="mastopexia.8"
                        defaultMessage="Procedimiento ambulatorio."
                        />
                    </p>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.4"
                        defaultMessage="Cuidados Post-Operatorios"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="mastopexia.9"
                        defaultMessage="Reposo de 2 a 3 semanas."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="mastopexia.10"
                        defaultMessage="Regreso al ejercicio a partir de 6 a 8 semanas."
                        />
                    </p>

                </div>

            </div>


            <h4 style={{color:"#0E766E"}}>
                <FormattedMessage
                id="titulos.5"
                defaultMessage="¿Necesitas un procedimiento personalizado?"
                />
            </h4>

            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-3" href='/cita'>
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>


            <h4>
                <FormattedMessage
                id="titulos.6"
                defaultMessage="Casos reales:"
                />
            </h4>

            <div className='container row'>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={mastopexia3} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={mastopexia4} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={mastopexia5} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={mastopexia6} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={mastopexia7} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={mastopexia8} alt=""></img>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Mastopexia;