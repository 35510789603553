import React, { Fragment, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import { langContext } from '../Context/langContext';

import ritidoplastia1 from '../Images/4-Cirugia-facial/3-RITIDOPLASTIA/1-PORTADA-1400.png';
import ritidoplastia6 from '../Images/4-Cirugia-facial/3-RITIDOPLASTIA/1-PORTADA-1400-en.png';
import ritidoplastia2 from '../Images/4-Cirugia-facial/3-RITIDOPLASTIA/12.png'
import ritidoplastia3 from '../Images/4-Cirugia-facial/3-RITIDOPLASTIA/CASOS-REALES/1.png'
import ritidoplastia4 from '../Images/4-Cirugia-facial/3-RITIDOPLASTIA/CASOS-REALES/2.jpg'
import ritidoplastia5 from '../Images/4-Cirugia-facial/3-RITIDOPLASTIA/CASOS-REALES/3.jpg'


const Ritidoplastia = props => {

    const idioma = useContext(langContext).obtenerLenguaje();

    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });

    return (

        <Fragment>

            {idioma === "es-MX" ? (
                <img className="img-ma" src={ritidoplastia1} alt=""></img>
            ) : (
                <img className="img-ma" src={ritidoplastia6} alt=""></img>
            )}

            <h4 style={{marginTop:"-80px"}}>
                <FormattedMessage
                id="titulos.1"
                defaultMessage="Descripción"
                />
            </h4>

            <div className='contenedor txt-separacion'>
                <p className='txt-ma' style={{marginTop:"60px"}}>
                    <FormattedMessage
                    id="ritidoplastia.1"
                    defaultMessage="Los efectos del paso del tiempo, la exposición prolongada de la piel al sol o el estrés diario se hacen especialmente 
                                    visibles en la cara y el cuello. Comienzan a aparecer surcos y pliegues en el rostro que hacen que la expresión facial 
                                    pierda definición y tono."
                    />
                </p>

                <p className='txt-ma' style={{marginTop:"60px"}}>
                    <FormattedMessage
                    id="ritidoplastia.2"
                    defaultMessage="Con un Lifting Facial es posible reacomodar todos los tejidos de la cara, reposicionando el tejido profundo llamado 
                                    SMAS, con la cual podemos eliminar las arrugas en el rostro, dando una apariencia más joven."
                    />
                </p>

            </div>


            <div className="flexbox contenedor" style={{marginTop: "100px", marginBottom: "100px"}}>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-ritidoplastia2" src={ritidoplastia2} alt=""></img>
                </div>

                <div className='flexbox-hijo order-2'>

                    <h4 className="cambio-posicion">
                        <FormattedMessage
                        id="titulos.2"
                        defaultMessage="¿Quiénes son candidatos?"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="ritidoplastia.3"
                        defaultMessage="Los pacientes ideales son mayores de 50 años o que presenten datos de caída del tejido facial con 
                                        arrugas marcada."
                        />
                    </p>

                    <h4 className="cambio-posicion">
                        <FormattedMessage
                        id="titulos.3"
                        defaultMessage="Preparación/Anestesia"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="ritidoplastia.4"
                        defaultMessage="Cirugía con anestesia local y sedación."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="ritidoplastia.5"
                        defaultMessage="Este procedimiento requiere 1 noche de hospital."
                        />
                    </p>

                    <h4 className="cambio-posicion">
                        <FormattedMessage
                        id="titulos.4"
                        defaultMessage="Cuidados Post-Operatorios"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="ritidoplastia.6"
                        defaultMessage="Reposo de entre 2 semanas."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="ritidoplastia.7"
                        defaultMessage="Regreso a la vida normal a partir de 15 días."
                        />
                    </p>

                </div>

            </div>


            <h4 style={{color:"#0E766E"}}>
                <FormattedMessage
                id="titulos.5"
                defaultMessage="¿Necesitas un procedimiento personalizado?"
                />
            </h4>

            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-3" href='/cita'>
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>


            <h4>
                <FormattedMessage
                id="titulos.6"
                defaultMessage="Casos reales:"
                />
            </h4>

            <div className='container row'>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ritidoplastia3} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ritidoplastia4} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria3'>
                        <img className='imagenes_galeria' src={ritidoplastia5} alt=""></img>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Ritidoplastia;