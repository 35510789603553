import React, { useState } from 'react';
import IdiomaIngles from "../Languages/en-US.json";
import IdiomaEspanol from "../Languages/es-MX.json";
import {IntlProvider} from 'react-intl';

const langContext = React.createContext();

const LangProvider = ({ children }) => {
    let localePorDefecto = 'es-MX';
	let idiomaPorDefecto = IdiomaEspanol;
	const lang = localStorage.getItem('_lang_dra_po');

	if(lang){
		localePorDefecto = lang

		if(lang === 'es-MX'){
			idiomaPorDefecto = IdiomaEspanol;
		} else if(lang === 'en-US'){
			idiomaPorDefecto = IdiomaIngles
		} else {
			localePorDefecto = 'es-MX'
			idiomaPorDefecto = IdiomaEspanol
		}
	}

    const [idioma, establecerIdioma] = useState(idiomaPorDefecto);
    const [locale, establecerLocale] = useState(localePorDefecto);

    const establecerLenguaje = (idioma) => {
        switch (idioma){
			case 'es-MX':
				establecerIdioma(IdiomaEspanol);
				establecerLocale('es-MX');
				localStorage.setItem('_lang_dra_po', 'es-MX');
				break;
			case 'en-US':
				establecerIdioma(IdiomaIngles);
				establecerLocale('en-US');
				localStorage.setItem('_lang_dra_po', 'en-US');
				break;
			default:
				establecerIdioma(IdiomaEspanol);
				establecerLocale('es-MX');
				localStorage.setItem('_lang_dra_po', 'es-MX');
		}
    }

	const obtenerLenguaje = () => {
		return locale;
	}

    return ( 
        <langContext.Provider value={{establecerLenguaje: establecerLenguaje, obtenerLenguaje:obtenerLenguaje}}>
            <IntlProvider locale={locale} messages={idioma} >
				{children}
			</IntlProvider>
        </langContext.Provider>
    );
}
 
export {LangProvider, langContext};