import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import idx2 from'../Images/ICONOS/1.png';
import idx3 from'../Images/ICONOS/10.png';
import idx4 from'../Images/ICONOS/3.png';
import idx5 from'../Images/ICONOS/4.png';
import idx6 from '../Images/1-INICIO/2-DR-JAVIER.png';
import idx7 from '../Images/1-INICIO/3-CIRUGIA-FACIAL.png';
import idx8 from '../Images/1-INICIO/4-CIRUGIA-CORPORAL.png';
import idx9 from '../Images/1-INICIO/5-SIN-CIRUGIA.png';
import idx10 from '../Images/1-INICIO/6-RENUVION.png';
import idx11 from '../Images/1-INICIO/7-MICROAIRE.png';
import idx12 from '../Images/1-INICIO/8-VASER.png';
import idx13 from '../Images/1-INICIO/9-TESTIMONIOS.png';
import idx14 from '../Images/1-INICIO/GALERIA/DSC00940.jpg';
import idx15 from '../Images/1-INICIO/GALERIA/IMG_9070.jpg';
import idx16 from '../Images/1-INICIO/GALERIA/DSC00451.jpg';
import idx17 from '../Images/1-INICIO/GALERIA/IMG_1482-2.jpg';
import idx18 from '../Images/1-INICIO/GALERIA/DSC00952.jpg';

import MapaDatos from '../Components/MapaDatos'
import Galeria from '../Components/Galeria'



const Index = props => {

    /* Se inicia el carrusel de imagenes */ 
    useEffect(() => {
        var elems = document.querySelectorAll('.carousel');
        M.Carousel.init(elems, {
            fullWidth: true,
            indicators: true
        });

        var elems_1 = document.querySelectorAll('.modal');
        M.Modal.init(elems_1);

        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });
    

    return (
        <Fragment>

            <Galeria/>

            <div className='separacion' style={{position:"relative", zIndex:"2"}}>
            
                <div className="row sombra-1" style={{paddingBottom:"25px", background:"white"}}>

                    <a href='/abdominoplastia' style={{color:"black"}}>
                        <div className="col s6 m3" style={{textAlign:"center"}}>
                            <div className='linea_1'></div>
                            <img style={{width:"38%", paddingTop:"40px"}} src={idx3} alt=""/>
                            <p className='texto-imagen'>
                                <FormattedMessage
                                id="index.2"
                                defaultMessage="Abdominoplastía"
                                />
                            </p>    
                        </div>
                    </a>

                    <a href='/lipoescultura' style={{color:"black"}}>
                        <div className="col s6 m3" style={{textAlign:"center"}}>
                            <img style={{width:"38%", paddingTop:"40px"}} src={idx2} alt=""/>
                            <p className='texto-imagen'>
                                <FormattedMessage
                                id="index.1"
                                defaultMessage="Lipoescultura"
                                />
                            </p>
                        </div>
                    </a>
                    
                    <a href='/mamoplastia-de-aumento' style={{color:"black"}}>
                        <div className="col s6 m3" style={{textAlign:"center"}}>
                            <img style={{width:"38%", paddingTop:"40px"}} src={idx4} alt=""/>
                            <p className='texto-imagen'>
                                <FormattedMessage
                                id="index.3"
                                defaultMessage="Mamoplastía"
                                />
                            </p>
                            <p className='texto-imagen'style={{marginTop:"-15px"}}>
                                <FormattedMessage
                                id="index.3.1"
                                defaultMessage="de Aumento"
                                />
                            </p>   
                        </div>
                    </a>

                    
                    <a href='/gluteoplastia' style={{color:"black"}}>
                        <div className="col s6 m3" style={{textAlign:"center"}}>
                            <img style={{width:"38%", paddingTop:"40px"}} src={idx5} alt=""/>
                            <p className='texto-imagen'>
                                <FormattedMessage
                                id="index.4"
                                defaultMessage="Aumento Glúteo"
                                />
                            </p>
                        </div>
                    </a>

                </div>

            </div>
 
            <h1 style={{paddingBottom:"100px", color:"#021D34"}}>
                <FormattedMessage
                id="index.5"
                defaultMessage="“Seguridad y Belleza Excepcional“"
                />
            </h1>


            <div className="flexbox separacion" style={{padding:"0px 5%"}}>

                <div className='flexbox-hijo order-2'>

                    <h1 style={{color:"black"}}>Dr. Javier Guerrero</h1>

                    <div className="linea-hor"></div>

                    <h2 style={{color:"#0E766E"}}>
                        <FormattedMessage
                        id="index.6"
                        defaultMessage="Cirujano Plástico Certificado"
                        />
                    </h2>

                    <div className="linea-hor texto"></div>

                    <p className='texto' style={{fontSize:"22px"}}>
                        <FormattedMessage
                        id="index.7"
                        defaultMessage="Realizó su especialidad médica en Cirugía Plástica en el Instituto Jalisciense de Cirugía 
                                        Reconstructiva Dr. José Guerrero Santos, capacitándose constantemente con algunos de los mejores y 
                                        más reconocidos cirujanos plásticos del país."
                        />
                    </p>

                    <br></br>

                    <p className='texto' style={{fontSize:"22px"}}>
                        <FormattedMessage
                        id="index.8"
                        defaultMessage="El servicio que ofrece es totalmente personalizado, ajustándose a sus necesidades 
                                        y tipo de cuerpo, con el fin de obtener los mejores resultados."
                        />
                    </p>

                    <div className='center-btn'>
                        <a class="waves-effect waves-light btn btn-conoce" href='/dr-javier'>
                            <FormattedMessage
                            id="index.btn"
                            defaultMessage="Conoce más"
                            />
                        </a>    
                    </div>

                </div>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-idx6" src={idx6} alt=""></img>
                </div>

            </div>
  

            <p className='titulo' style={{textAlign:"center", color:"#0E766E", fontSize:"44px"}}>
                <FormattedMessage
                id="index.9"
                defaultMessage="Especialidades"
                />
            </p>


            <div className="container padre_1">

                <a href='/cirugia-facial'>
                    <div className="hijo_2">

                        <div className='texto_hijo_2'>
                            <p className='texto-interno'>
                                <FormattedMessage
                                id="index-img-1"
                                defaultMessage="Cirugía"
                                />
                            </p>
                            <p className='texto-interno'>
                                <FormattedMessage
                                id="index-img-1.2"
                                defaultMessage="Facial"
                                />
                            </p>
                        </div>

                        <div className='imagen_hover'>
                            <img style={{width:"100%"}} src={idx7} alt=""></img>
                        </div>

                    </div>
                </a>

                <a href='/cirugia-corporal'>
                    <div className="hijo_2">

                        <div className='texto_hijo_2'>
                            <p className='texto-interno'>
                                <FormattedMessage
                                id="index-img-2"
                                defaultMessage="Cirugía"
                                />
                            </p>
                            <p className='texto-interno'>
                                <FormattedMessage
                                id="index-img-2.2"
                                defaultMessage="Corporal"
                                />
                            </p>
                        </div>

                        <div className='imagen_hover'>
                            <img style={{width:"100%"}} src={idx8} alt=""></img>
                        </div>

                    </div>
                </a>

                <a href='/sin-cirugia'>
                    <div className="hijo_2">

                        <div className='texto_hijo_2'>
                            <p className='texto-interno'>
                                <FormattedMessage
                                id="index-img-3"
                                defaultMessage="Sin"
                                />
                            </p>
                            <p className='texto-interno'>
                                <FormattedMessage
                                id="index-img-3.2"
                                defaultMessage="Cirugía"
                                />
                            </p>
                        </div>

                        <div className='imagen_hover'>
                            <img style={{width:"100%"}} src={idx9} alt=""></img>
                        </div>

                    </div>
                </a>

            </div>


            <p className='texto-idx-1 titulo'>
                <FormattedMessage
                id="index.10"
                defaultMessage="Nuestro objetivo es ayudarte a obtener"
                />
            </p>
            <p className='texto-idx-2 titulo'>
                <FormattedMessage
                id="index.11"
                defaultMessage="la apariencia que siempre has deseado."
                />
            </p>


            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-2" href='/cita'>
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>

            <p className='titulo2' style={{textAlign:"center", fontSize:"22px", marginBottom:"-10px"}}>
                <FormattedMessage
                id="index.12"
                defaultMessage="Lo más innovador en"
                />
            </p>

            <p className='titulo' style={{textAlign:"center", fontSize:"44px", color:"#0E766E", marginTop:"-10px"}}>
                <FormattedMessage
                id="index.13"
                defaultMessage="Aparatología"
                />
            </p>


            <div className="container padre_1">

                <div class="modal-trigger" href="#modal1" style={{cursor:"pointer"}}>
                        <div className="hijo_3 sombra-2">
                            <img style={{width:"100%"}} src={idx10} alt=""></img>
                            <p className="titulo2" style={{fontSize:"22px", marginBottom:"-10px"}}>Renuvion</p>
                            <p className="titulo2" style={{fontSize:"22px", marginTop:"-10px"}}>J-Plasma</p>
                        </div>
                </div>
                <div id="modal1" class="modal">
                        <div class="modal-content">
                            <h4>Renuvion J-Plasma</h4>
                            <p className='texto'>
                                <FormattedMessage
                                id="index-img-4"
                                defaultMessage="El Renuvion es un dispositivo de energía avanzada que combina las propiedades únicas del Plasma de 
                                                Helio frío con energía de radiofrecuencia."
                                />
                            </p>
                            <p className='texto'>
                                <FormattedMessage
                                id="index-img-4.2"
                                defaultMessage="Renuvion utiliza la energía de un Plasma frío a nivel subdermal, para tensar y rejuvenecer la piel de manera 
                                                instantánea, en cualquier parte del cuerpo o de la cara."
                                />
                            </p>
                        </div>
                </div>

                <div class="modal-trigger" href="#modal2" style={{cursor:"pointer"}}>
                    <div className="hijo_3 sombra-2">
                        <img style={{width:"100%", marginBottom:"20px"}} src={idx11} alt=""></img>
                        <p className="titulo2" style={{fontSize:"22px"}}>Microaire</p>
                    </div>
                </div>
                <div id="modal2" class="modal">
                        <div class="modal-content">
                            <h4>Microaire</h4>
                            <p className='texto'>
                                <FormattedMessage
                                id="index-img-5"
                                defaultMessage="Es una técnica revolucionaria en liposucción y cirugía corporal que permite esculpir el cuerpo al 
                                                milímetro."
                                />
                            </p>
                            <p className='texto'>
                                <FormattedMessage
                                id="index-img-5.2"
                                defaultMessage="Consiguiendo cuerpos tonificados y atléticos sin necesidad de ir al gimnasio, siempre que se esté entre 
                                                los candidatos indicados."
                                />
                            </p>
                        </div>
                </div>

                <div class="modal-trigger" href="#modal3" style={{cursor:"pointer"}}>
                    <div className="hijo_3 sombra-2">
                        <img style={{width:"100%", marginBottom:"20px"}} src={idx12} alt=""></img>
                        <p className="titulo2" style={{fontSize:"22px"}}>Vaser</p>
                    </div>
                </ div>
                <div id="modal3" class="modal">
                    <div class="modal-content">
                        <h4>Vaser</h4>
                        <p className='texto'>
                            <FormattedMessage
                            id="index-img-6"
                            defaultMessage="La innovadora tecnología Lipo Vaser® es un procedimiento de liposucción que actúa selectivamente
                                            sobre el tejido graso y consigue hacer líquida la grasa facilitando la extracción y eliminación 
                                            del cuerpo."
                            />
                        </p>
                    </div>
                </div>

            </div>


            <p className='titulo' style={{textAlign:"center", fontSize:"44px", color:"#0E766E", marginTop:"100px"}}>
                <FormattedMessage
                id="index.14"
                defaultMessage="Testimonios"
                />
            </p>


            <div className='row contenedor'>

                <div className='col s12 m5 center'>
                    <img className='img-idx13' src={idx13} alt=""></img>
                </div>

                <div className='col s12 m7'>
                    <div class="carousel carousel-slider center" style={{height:"500px"}}>

                        <div class="carousel-item flexbox-col">
                            <div className='sombra-3 carousel-or'>
                                <div style={{display:"flex", flexWrap:"wrap", justifyContent:"left"}}>
                                    <p className='titulo2' style={{padding:"0px 5%", fontSize:"22px"}}>
                                        <FormattedMessage
                                        id="index-op-1"
                                        defaultMessage="Excelente -"
                                        />
                                    </p>
                                    <p style={{color:"#B28B07", fontSize:"22px"}}>★★★★★</p>
                                </div>
                                <p className='texto' style={{fontStyle:"italic", textAlign:"justify", padding:"0px 5%"}}>
                                    <FormattedMessage
                                    id="index-op-1.1"
                                    defaultMessage="La cita con el Dr. Javier fue excelente, aclaró todas mis dudas, 
                                    me dio a conocer las expectativas de la cirugía (rinoplastia) y 
                                    la ejecución, trato y resultados… simplemente formidables y 
                                    excelentes, es una gran opción y estoy muy contenta de 
                                    haberlo elegido como mi cirujano."
                                    />
                                </p>
                            </div>
                            <p className="texto-idx-3 titulo2">Liliana P.</p>
                            <p className='titulo2' style={{textAlign:"center", fontSize:"11px", marginTop:"-20px"}}>
                                <FormattedMessage
                                id="index-op-cir-1"
                                defaultMessage="Cirugía - Rinoplastía"
                                />
                            </p>
                        </div>

                        <div class="carousel-item flexbox-col">
                            <div className='sombra-3 carousel-or'>
                                <div style={{display:"flex", flexWrap:"wrap", justifyContent:"left"}}>
                                    <p className='titulo2' style={{padding:"0px 5%", fontSize:"22px"}}>
                                        <FormattedMessage
                                        id="index-op-1"
                                        defaultMessage="Excelente -"
                                        />
                                    </p>
                                    <p style={{color:"#B28B07", fontSize:"22px"}}>★★★★★</p>
                                </div>
                                <p className='texto' style={{fontStyle:"italic", textAlign:"justify", padding:"0px 5%"}}>
                                    <FormattedMessage
                                    id="index-op-2"
                                    defaultMessage="Mi doctor favorito."
                                    />
                                </p>
                                <p className='texto' style={{fontStyle:"italic", textAlign:"justify", padding:"0px 5%"}}>
                                    <FormattedMessage
                                    id="index-op-2.1"
                                    defaultMessage="Deseo que dios lo bendiga y le de mucha salud para que siga ayudando a tantas mujeres como yo, a recuperar su autoestima. 
                                                    En especial le agradezco de todo corazón lo que hizo por mi, mi matrimonio y sobretodo mi cuerpo."
                                    />
                                </p>
                                <p className='texto' style={{fontStyle:"italic", textAlign:"justify", padding:"0px 5%"}}>
                                    <FormattedMessage
                                    id="index-op-2.2"
                                    defaultMessage="Gracias, gracias, gracias y que el nuevo año llegue lleno de cosas lindas para usted y 
                                                    su familia."
                                    />
                                </p>
                            </div>
                            <p className="texto-idx-3 titulo2">Connie C.</p>
                            <p className='titulo2' style={{textAlign:"center", fontSize:"11px", marginTop:"-20px"}}>
                                <FormattedMessage
                                id="index-op-cir-2"
                                defaultMessage="Cirugía - Lipoabdominoplastia"
                                />
                            </p>
                        </div>

                        <div class="carousel-item flexbox-col">
                            <div className='sombra-3 carousel-or'>
                                <div style={{display:"flex", flexWrap:"wrap", justifyContent:"left"}}>
                                    <p className='titulo2' style={{padding:"0px 5%", fontSize:"22px"}}>
                                        <FormattedMessage
                                        id="index-op-1"
                                        defaultMessage="Excelente -"
                                        />
                                    </p>
                                    <p style={{color:"#B28B07", fontSize:"22px"}}>★★★★★</p>
                                </div>
                                <p className='texto' style={{fontStyle:"italic", textAlign:"justify", padding:"0px 5%"}}>
                                    <FormattedMessage
                                    id="index-op-3"
                                    defaultMessage="Mil gracias, estoy super feliz con su hermoso trabajo."
                                    />
                                </p>
                                <p className='texto' style={{fontStyle:"italic", textAlign:"justify", padding:"0px 5%"}}>
                                    <FormattedMessage
                                    id="index-op-3.1"
                                    defaultMessage="Gracias por devolverme mi felicidad, me siento mucho más bonita cada que me veo al espejo,
                                                     definitivamente mi autoestima subió gracias a usted."
                                    />
                                </p>
                            </div>
                            <p className="texto-idx-3 titulo2">Shanty P.</p>
                            <p className='titulo2' style={{textAlign:"center", fontSize:"11px", marginTop:"-20px"}}>
                                <FormattedMessage
                                id="index-op-cir-3"
                                defaultMessage="Cirugía - Mommy Make Over"
                                />
                            </p>
                        </div>

                    </div>
                </div>

            </div>


            <p className='titulo' style={{textAlign:"center", fontSize:"44px", color:"#0E766E", marginTop:"100px"}}>
                <FormattedMessage
                id="index.15"
                defaultMessage="Contáctanos"
                />
            </p>

            <MapaDatos/>

            <p className='titulo' style={{textAlign:"center", fontSize:"44px", color:"#0E766E", marginTop:"100px"}}>
                <FormattedMessage
                id="index.18"
                defaultMessage="Galería"
                />
            </p>


            <div className='container row' style={{marginBottom:"100px"}}>

                <div className='col s12'>
                    <img className='materialboxed img-idx14' src={idx14} alt=""></img>
                </div>

                <div className='col s6 m3'>
                    <div className='materialboxed img-galeria img-espacio'>
                        <img className='img-idx15' src={idx15} alt=""></img>
                    </div>
                </div>

                <div className='col s6 m3'>
                    <div className='materialboxed img-galeria img-espacio'>
                        <img className='img-idx16' src={idx16} alt=""></img>
                    </div>
                </div>

                <div className='col s6 m3'>
                    <div className='materialboxed img-galeria img-espacio'>
                        <img className='img-idx17' src={idx17} alt=""></img>
                    </div>
                </div>

                <div className='col s6 m3'>
                    <div className='materialboxed img-galeria img-espacio'>
                        <img className='img-idx18' src={idx18} alt=""></img>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Index;
