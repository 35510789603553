import React, {Fragment, useContext, useEffect} from 'react';
import M from 'materialize-css';
import { langContext } from '../Context/langContext';
import { FormattedMessage } from 'react-intl';

import hdr1 from '../Images/1-INICIO/Logo.png';
import hdr2 from '../Images/ICONOS/18.png';
import hdr3 from '../Images/ICONOS/19.png';

const Header = () => {
    
    useEffect(() => {
      let vista = window.location.pathname;
      if (vista === "/") {
        let header = document.getElementById("header-doctor");
        header.className = "header-inicio";
      } else if (vista === "/gracias") {
        let header = document.getElementById("header-doctor");
        header.className = "header-inicio";
      }

      var elems = document.querySelectorAll('.sidenav');
      M.Sidenav.init(elems);
    });

    const idioma = useContext(langContext);

    return (
      <Fragment>
        <nav id='header-doctor'>

          <a href="#" data-target="mobile-demo" class="sidenav-trigger"><i class="material-icons" style={{color:"#0E766E"}}>menu</i></a>

          <div className="nav-wrapper hdr hdr-contenedor">

            <ul id="nav-mobile" className="hide-on-med-and-down">

              <li><a href="/" className="sin_hover"><img className="hdr-img" src={hdr1} alt=""/></a></li>

              <li><a className='hdr-hover' href="/">
                    <FormattedMessage
                        id="header.1"
                        defaultMessage="Inicio"
                    />
                  </a></li>

              <li><a className='hdr-hover' href="/dr-javier">
                      <FormattedMessage
                        id="header.2"
                        defaultMessage="Dr. Javier Guerrero"
                      />
                  </a></li>

              <li><a className='hdr-hover' href="/cirugia-corporal">
                    <FormattedMessage
                      id="header.4"
                      defaultMessage="Cirugía Corporal"
                    /> 
                  </a></li>

              <li><a className='hdr-hover' href="/cirugia-facial">
                      <FormattedMessage
                        id="header.3"
                        defaultMessage="Cirugía Facial"
                      />                  
                  </a></li>

              <li><a className='hdr-hover' href="/sin-cirugia">
                    <FormattedMessage
                      id="header.5"
                      defaultMessage="Sin Cirugía"
                    /> 
                  </a></li>

              <li><a className='hdr-hover' href="/instalaciones">
                    <FormattedMessage
                      id="header.6"
                      defaultMessage="Instalaciones"
                    />
                  </a></li>

              <li><a className='hdr-hover' href="/cita">
                    <FormattedMessage
                      id="header.7"
                      defaultMessage="Agenda una Cita"
                    />
                  </a></li>

              <li><a onClick={() => {idioma.establecerLenguaje('es-MX')}} href="#" className="sin_hover"><img src={hdr2} alt=""/></a></li>

              <li><a onClick={() => {idioma.establecerLenguaje('en-US')}} href="#" className="sin_hover"><img src={hdr3} alt=""/></a></li> 

            </ul>

          </div>

        </nav>

        <ul class="sidenav" id="mobile-demo">
          <li><a href="/">
                    <FormattedMessage
                        id="header.1"
                        defaultMessage="Inicio"
                    />
                  </a></li>

              <li><a href="/dr-javier">
                      <FormattedMessage
                        id="header.2"
                        defaultMessage="Dr. Javier Guerrero"
                      />
                  </a></li>

              <li><a href="/cirugia-facial">
                      <FormattedMessage
                        id="header.3"
                        defaultMessage="Cirugía Facial"
                      />                  
                  </a></li>

              <li><a href="/cirugia-corporal">
                    <FormattedMessage
                      id="header.4"
                      defaultMessage="Cirugía Corporal"
                    /> 
                  </a></li>
              <li><a href="/sin-cirugia">
                    <FormattedMessage
                      id="header.5"
                      defaultMessage="Sin Cirugía"
                    /> 
                  </a></li>

              <li><a href="/instalaciones">
                    <FormattedMessage
                      id="header.6"
                      defaultMessage="Instalaciones"
                    />
                  </a></li>

              <li><a href="/cita">
                    <FormattedMessage
                      id="header.7"
                      defaultMessage="Agenda una Cita"
                    />
                  </a></li>

                <li><a onClick={() => {idioma.establecerLenguaje('es-MX')}} href="#" className="sin_hover"><img className='img-hdr2' src={hdr2} alt=""/></a></li>

                <li><a onClick={() => {idioma.establecerLenguaje('en-US')}} href="#" className="sin_hover"><img className='img-hdr3' src={hdr3} alt=""/></a></li>  
        </ul>

      </Fragment>
    );
}

export default Header;
