import React, {useEffect} from 'react';
import M from 'materialize-css';

const Boton = () => {

    useEffect(() => {
        var elems = document.querySelectorAll('.fixed-action-btn');
        M.FloatingActionButton.init(elems);
    }, [])

    return (
        <div class="fixed-action-btn">
            <a class="btn-floating btn-large" style={{backgroundColor:"#127870"}}>
                <i class="fas fa-phone-alt"></i>
            </a>
            <ul>
                <li>
                    <a class="btn-floating" style={{backgroundColor:"#3b5998"}}
                        href='https://www.facebook.com/drjavierguerrero' target='blank'
                    >
                        <i class="fab fa-facebook-f"></i>
                    </a>
                </li>
                <li>
                    <a class="btn-floating darken-1" style={{backgroundColor:"#E1306C"}} 
                        href='https://www.instagram.com/dr_javier_guerrero/' target='blank'
                    >
                        <i class="fab fa-instagram"></i>
                    </a>
                </li>
                <li>
                    <a class="btn-floating" style={{backgroundColor:"#25d366"}}
                        href="https://wa.me/523312553538?text=¡Hola buen día! Me gustaría agendar una cita" target='blank'
                    >
                        <i class="fab fa-whatsapp"></i>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Boton;