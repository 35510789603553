import React, { Fragment, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import { langContext } from '../Context/langContext';

import lipoescultura1 from '../Images/3-Cirugia-corporal/3-Contorno corporal/1-LIPOESCULTURA/1-PORTADA-1400.png';
import lipoescultura9 from '../Images/3-Cirugia-corporal/3-Contorno corporal/1-LIPOESCULTURA/1-PORTADA-1400-en.png';
import lipoescultura2 from '../Images/3-Cirugia-corporal/3-Contorno corporal/1-LIPOESCULTURA/7.png'
import lipoescultura3 from '../Images/3-Cirugia-corporal/3-Contorno corporal/1-LIPOESCULTURA/CASOS REALES/1.png'
import lipoescultura4 from '../Images/3-Cirugia-corporal/3-Contorno corporal/1-LIPOESCULTURA/CASOS REALES/2.png'
import lipoescultura5 from '../Images/3-Cirugia-corporal/3-Contorno corporal/1-LIPOESCULTURA/CASOS REALES/3.jpg'
import lipoescultura6 from '../Images/3-Cirugia-corporal/3-Contorno corporal/1-LIPOESCULTURA/CASOS REALES/4.png'
import lipoescultura7 from '../Images/3-Cirugia-corporal/3-Contorno corporal/1-LIPOESCULTURA/CASOS REALES/5.jpg'
import lipoescultura8 from '../Images/3-Cirugia-corporal/3-Contorno corporal/1-LIPOESCULTURA/CASOS REALES/6.png'

const Lipoescultura = props => {

    const idioma = useContext(langContext).obtenerLenguaje();

    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });

    return (

        <Fragment>

            {idioma === "es-MX" ? (
                <img className="img-ma" src={lipoescultura1} alt=""></img>
            ) : (
                <img className="img-ma" src={lipoescultura9} alt=""></img>
            )}

            <h4 style={{marginTop:"-80px"}}>
                <FormattedMessage
                id="titulos.1"
                defaultMessage="Descripción"
                />
            </h4>

            

            <div className='contenedor txt-separacion efecto-lista'>
                <p className='txt-lista' style={{marginTop:"60px"}}>
                    <FormattedMessage
                    id="lipoescultura.1"
                    defaultMessage="La Liposucción mejora el contorno corporal eliminando los depósitos de grasa antiestéticos localizaos en diferentes 
                                    partes del cuerpo. Con Lipoescultura se puede extraer la grasa en la proporción deseada y apropiada para evitar las 
                                    acumulaciones que afectan la figura."
                    />
                </p>

                <p className='txt-lista'>
                    <FormattedMessage
                    id="lipoescultura.2"
                    defaultMessage="Liposucción= Extraer grasa"
                    />
                </p>

                <p className='txt-lista' style={{marginTop:"-20px"}}>
                    <FormattedMessage
                    id="lipoescultura.3"
                    defaultMessage="Lipoescultura = Moldear el cuerpo al extraer la grasa"
                    />
                </p>

                <p className='txt-lista'>
                    <FormattedMessage
                    id="lipoescultura.4"
                    defaultMessage="La lipoescultura completa abarca las siguientes áreas:"
                    />
                </p>

                <div style={{textAlign:"left"}}>

                    <p className='txt-lista' style={{marginTop:"-20px"}}>
                        <span className="circulo" style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="lipoescultura.5"
                        defaultMessage="Abdomen"
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-20px"}}>
                        <span className="circulo" style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="lipoescultura.6"
                        defaultMessage="Cintura"
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-20px"}}>
                        <span className="circulo" style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="lipoescultura.7"
                        defaultMessage="Espalda"
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-20px"}}>
                        <span className="circulo" style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="lipoescultura.8"
                        defaultMessage="Brazos"
                        />
                    </p>

                </div>

                <p className='txt-lista'>
                    <FormattedMessage
                    id="lipoescultura.9"
                    defaultMessage="Con opción de agregar:"
                    />
                </p>

                <div style={{textAlign:"left"}}>

                    <p className='txt-lista' style={{marginTop:"-20px"}}>
                        <span className="circulo" style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="lipoescultura.10"
                        defaultMessage="Entrepierna"
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-20px"}}>
                        <span className="circulo" style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="lipoescultura.11"
                        defaultMessage="Cuello"
                        />
                    </p>

                </div>

                <p className='txt-lista'>
                    <FormattedMessage
                    id="lipoescultura.12"
                    defaultMessage="Infiltración de grasa:"
                    />
                </p>

                <p className='txt-lista' style={{marginTop:"-20px"}}>
                    <FormattedMessage
                    id="lipoescultura.13"
                    defaultMessage="La grasa obtenida gracias a la liposucción podrá ser infiltrada en glúteos y cadera para mejorar la forma y volumen."
                    />
                </p>

                <p className='txt-lista'>
                    <FormattedMessage
                    id="lipoescultura.14"
                    defaultMessage="¿Cuánto se puede infiltrar?"
                    />
                </p>

                <p className='txt-lista' style={{marginTop:"-20px"}}>
                    <FormattedMessage
                    id="lipoescultura.15"
                    defaultMessage="Dependerá de los deseos de la paciente, de la cantidad de grasa que se pueda obtener y la capacidad en la región glútea respetando el límite."
                    />
                </p>

            </div>


            <div className="flexbox contenedor" style={{marginTop: "100px", marginBottom: "100px"}}>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-lipo2" src={lipoescultura2} alt=""></img>
                </div>

                <div className='flexbox-hijo order-2'>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.2"
                        defaultMessage="¿Quiénes son candidatos?"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="lipoescultura.16"
                        defaultMessage="Pacientes que se encuentren dentro del rango de su peso ideal y presentan grasa localizada."
                        />
                    </p>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="lipoescultura.17"
                        defaultMessage="*Pacientes con obesidad (índice de masa corporal mayor a 30) no son candidatos para esta cirugía."
                        />
                    </p>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.3"
                        defaultMessage="Preparación/Anestesia"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="lipoescultura.18"
                        defaultMessage="Cirugía con anestesia regional: bloqueo epidural y sedación."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="lipoescultura.19"
                        defaultMessage="Este procedimiento requiere 1 noche de hospital."
                        />
                    </p>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.4"
                        defaultMessage="Cuidados Post-Operatorios"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="lipoescultura.20"
                        defaultMessage="Se dejan drenajes y se retiran a los 5 días."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="lipoescultura.21"
                        defaultMessage="Uso de faja por 2 o tres meses (dependerá si se utiliza aparatología como VASER o RENUVION)."
                        />
                    </p>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="lipoescultura.22"
                        defaultMessage="Recuperación de 10 a 14 días."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="lipoescultura.23"
                        defaultMessage="Regreso al ejercicio a partir de 4 a 6 semanas después."
                        />
                    </p>

                </div>

            </div>


            <h4 style={{color:"#0E766E"}}>
                <FormattedMessage
                id="titulos.5"
                defaultMessage="¿Necesitas un procedimiento personalizado?"
                />
            </h4>

            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-3" href='/cita'>
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>


            <h4>
                <FormattedMessage
                id="titulos.6"
                defaultMessage="Casos reales:"
                />
            </h4>

            <div className='container row'>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={lipoescultura3} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={lipoescultura4} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={lipoescultura5} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={lipoescultura6} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={lipoescultura7} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={lipoescultura8} alt=""></img>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Lipoescultura;