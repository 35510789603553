import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import firebaseHelper from '../../../Helpers/Firebase';


class EditarCategoria extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            nombre: ''
        }
    }

    editar = e => {
        const { history } = this.props;
        e.preventDefault();

        var editar = {...this.state};
        
        //extraer firestor de props
        const { firestore } = this.props;

        //actualizacion en la base de datos
        firebaseHelper.editar(this.props.categorias.id, 'categoria', firestore, editar, '/admin/categorias', history);
        
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidUpdate = () => {
        if (this.state.nombre === '') {
            this.setState({
                nombre: this.props.categorias.nombre
            });
        }
    }

    render(){
        if (!this.props.categorias) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="EDITAR CATEGORIA" />
                    <div id="font-text" className="container center" style={{marginTop:'0px'}}>
                            <form onSubmit={this.editar}>
                                <div style={{margin:'0 15%'}}>
                                    <div class="input-field col s12">
                                        <input required onChange={this.leerDato} value={this.state.nombre} name="nombre" id="nombre" type="text" class="validate" />
                                        <label class="active" for="nombre">Nombre Categoria</label>
                                    </div>
                                </div>
                                <button id="font-button" class="btn waves-effect waves-light" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#e57373 '}}>EDITAR</button>
                                <Link to={'/admin/categorias'} id="font-button" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#e57373 '}}>REGRESAR</Link>
                            </form>
                    </div>
                </Fragment>
            );
        }
    }
}

EditarCategoria.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        { 
            collection: 'categoria',
            storeAs: 'categorias',
            doc: props.match.params.id
        } 
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        categorias: ordered.categorias && ordered.categorias[0]
    }))
)(EditarCategoria)