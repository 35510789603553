import React, {Fragment, Component}  from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


class Imagenes extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            nombre: ''
        }
    }

    render(){
        if (!this.props.categoria) {
            return (
                <Fragment>
                    <Header />
                    <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                        <div class="preloader-wrapper big active">
                            <div class="spinner-layer spinner-red darken-4-only">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="gap-patch">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header mensaje="IMAGENES" />

                    <div className="btn-outline-light container" style={{padding: '30px 0 30px 0'}}>
                        <Link to={`/admin/imagenes-categorias`} id="font-button" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#e57373 ', marginTop:'25px'}}>REGRESAR</Link>
                    </div>

                    {this.props.productos === undefined ? (
                        window.location.reload()
                    ) : (    
                        this.props.productos.length === 0 ? (
                            <div className="center">
                                <h2 id="font">No hay productos registrados</h2>
                            </div>
                        ) : (
                            this.props.categoria.map(datoSub => (
                                <div className="container">
                                    <div style={{display:'flex'}}>
                                        <h5 id="font">{datoSub.nombre}</h5>  
                                    </div>
                                    <Table striped bordered hover style={{marginBottom:'50px'}}>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Precio</th>
                                                <th>Cantidad</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody id="font">
                                            {this.props.productos.map(dato => (
                                                datoSub.id === dato.categoria ? (
                                                    <tr key={dato.id}>
                                                        <td>{dato.nombre}</td>
                                                        <td>{dato.precio}</td>
                                                        <td>{dato.cantidad}</td>
                                                        <td>
                                                            {dato.urlImagen === "" ? (
                                                                <Link style={{marginLeft:'14%'}} to={`/admin/nueva-imagen-producto/${dato.id}`}><i class="material-icons" style={{color:'#e57373 '}}>add_circle</i></Link>
                                                            ) : (
                                                                <Link style={{marginLeft:'14%'}} to={`/admin/editar-imagen-producto/${dato.id}`}><i class="material-icons" style={{color:'#e57373 '}}>create</i></Link>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <span></span>
                                                )
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            ))
                        )
                    )}
                    
                </Fragment>
            );
        }
    }
}

Imagenes.protoType = {
    firestor : PropTypes.object.isRequired
}

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'producto',
            storeAs: 'productos',
            where: [
                ['categoria', '==', props.match.params.id ]
            ]
        },
        {
            collection: 'categoria',
            orderBy:"nombre"
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        productos: ordered.productos,
        categoria: ordered.categoria
    }))
)(Imagenes);