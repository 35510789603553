import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import rf1 from '../Images/5-SIN-CIRUGÍA/RELLENO-FACIAL/1-PORTADA-1400.png';

const Rellenos_Faciales = props => {

    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });

    return (

        <Fragment>

            <img className="img-ma" src={rf1} alt=""></img>

            <h4 style={{marginTop:"-80px"}}>
                <FormattedMessage
                id="titulos.1"
                defaultMessage="Descripción"
                />
            </h4>

            <div className='contenedor txt-separacion efecto-lista'>

                <p className='txt-ma' style={{marginTop:"60px", marginBottom:"30px"}}>
                    <FormattedMessage
                    id="rf.1"
                    defaultMessage="Existen diferentes tipos de rellenos faciales que se pueden aplicar en consultorio."
                    />
                </p>

                <p className='txt-ma' style={{marginTop:"-20px", marginBottom:"30px"}}>
                    <FormattedMessage
                    id="rf.2"
                    defaultMessage="Funcionan para mejorar líneas de expresión y arrugas faciales."
                    />
                </p>

                <p className='txt-ma' style={{marginBottom:"30px"}}>
                    <FormattedMessage
                    id="rf.3"
                    defaultMessage="El más común es el ácido hialurónico, su efecto tiene duración aproximada de 1 año."
                    />
                </p>

                <p className='txt-ma' style={{marginBottom:"30px"}}>
                    <FormattedMessage
                    id="rf.4"
                    defaultMessage="Las áreas más comunes en donde se coloca son:"
                    />
                </p>

                <div style={{textAlign:"left"}}>

                    <p className='txt-lista'>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="rf.5"
                        defaultMessage="Párpados inferiores"
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-15px"}}>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="rf.6"
                        defaultMessage="Surco nasolabial"
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-15px"}}>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="rf.7"
                        defaultMessage="Labios"
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-15px"}}>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="rf.8"
                        defaultMessage="Pómulos"
                        />
                    </p>

                    <p className='txt-lista' style={{marginTop:"-15px"}}>
                        <span className='circulo' style={{color:"#0E766E"}}>● &nbsp; &nbsp;</span>
                        <FormattedMessage
                        id="rf.9"
                        defaultMessage="Marcaje mandibular"
                        />
                    </p>

                </div>

            </div>


            <h4 style={{color:"#0E766E"}}>
                <FormattedMessage
                id="titulos.5"
                defaultMessage="¿Necesitas un procedimiento personalizado?"
                />
            </h4>

            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-3" href='/cita'>
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>

        </Fragment>
    )
}

export default Rellenos_Faciales;