import React, { Fragment, useEffect, useContext  } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import { langContext } from '../Context/langContext';

import mr1 from '../Images/3-Cirugia-corporal/2-mamas/REDUCCION/1-PORTADA-1400.png';
import mr6 from '../Images/3-Cirugia-corporal/2-mamas/REDUCCION/1-PORTADA-1400-en.png';
import mr2 from '../Images/3-Cirugia-corporal/2-mamas/REDUCCION/6.png'
import mr3 from '../Images/3-Cirugia-corporal/2-mamas/REDUCCION/CASOS-REALES/1.jpg'
import mr4 from '../Images/3-Cirugia-corporal/2-mamas/REDUCCION/CASOS-REALES/2.png'
import mr5 from '../Images/3-Cirugia-corporal/2-mamas/REDUCCION/CASOS-REALES/3.png'

const Mamoplastia_de_Reduccion = props => {
    
    const idioma = useContext(langContext).obtenerLenguaje();

    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });

    return (

        <Fragment>

            {idioma === "es-MX" ? (
                <img className="img-ma" src={mr1} alt=""></img>
            ) : (
                <img className="img-ma-en" src={mr6} alt=""></img>
            )}


            <h4 style={{marginTop:"-80px"}}>
                <FormattedMessage
                id="titulos.1"
                defaultMessage="Descripción"
                />
            </h4>

            <div className='contenedor txt-separacion'>
                <p className='txt-ma' style={{marginTop:"60px"}}>
                    <FormattedMessage
                    id="mr.1"
                    defaultMessage="Una reducción de mamas, también conocida como mamoplastia de reducción es un procedimiento quirúrgico para reducir 
                                    el tamaño y mejorar la forma de las mamas."
                    />
                </p>
            </div>


            <div className="flexbox contenedor" style={{marginTop: "100px", marginBottom: "100px"}}>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-ma2" src={mr2} alt=""></img>
                </div>

                <div className='flexbox-hijo order-2'>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.2"
                        defaultMessage="¿Quiénes son candidatos?"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="mr.2"
                        defaultMessage="Pacientes que tienen síntomas físicos relacionados con el peso o la forma de sus mamas. Estos síntomas incluyen 
                                        dolores de espalda, del cuello y estrías en las mamas, incluso surcos en las zonas de los hombros donde se apoyan los 
                                        tirantes del sujetador."
                        />
                    </p>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.3"
                        defaultMessage="Preparación/Anestesia"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="mr.3"
                        defaultMessage="Cirugía con anestesia bloqueo epidural y sedación."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="mr.4"
                        defaultMessage="Procedimiento ambulatorio."
                        />
                    </p>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.4"
                        defaultMessage="Cuidados Post-Operatorios"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="mr.5"
                        defaultMessage="Reposo de 2 a 3 semanas."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="mr.6"
                        defaultMessage="Regreso al ejercicio a partir de 6 a 8 semanas."
                        />
                    </p>

                </div>

            </div>


            <h4 style={{color:"#0E766E"}}>
                <FormattedMessage
                id="titulos.5"
                defaultMessage="¿Necesitas un procedimiento personalizado?"
                />
            </h4>

            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-3" href='/cita'>
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>


            <h4>
                <FormattedMessage
                id="titulos.6"
                defaultMessage="Casos reales:"
                />
            </h4>

            <div className='container row'>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria4 img-espacio'>
                        <img className='imagenes_galeria' src={mr3} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria4 img-espacio'>
                        <img className='imagenes_galeria' src={mr4} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria4 img-espacio'>
                        <img className='imagenes_galeria' src={mr5} alt=""></img>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Mamoplastia_de_Reduccion;