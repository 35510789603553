import React, {Fragment, Component}  from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../../../Components/SideNav';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import firebaseHelper from '../../../Helpers/Firebase';


class NuevoProducto extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            nombre: '',
            especificacion: '',
            precio: '',
            cantidad: '',
            urlImagen: '',
            categoria: this.props.match.params.id,
            precioMayoreo: ''
        }
    }

    componentDidUpdate = () => {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
    }

    agregar = e => {
        const { history } = this.props;
        e.preventDefault();
    
        //extraer firestor de props
        const { firestore } = this.props;

        //guardado en la base de datos
        var nuevo = {...this.state}

        firebaseHelper.agregar('producto', firestore, nuevo, `/admin/productos/${this.props.match.params.id}`, history);
    }

    leerDato = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    render(){
        return (
            <Fragment>
                <Header mensaje="NUEVO PRODUCTO" />
                <div id="font" className="container">    
                    <div className="center" style={{padding: '30px 0 30px 0'}}>
                        <form onSubmit={this.agregar}>
                            <div style={{margin:'0 15%'}}>
                                <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="nombre" id="nombre" type="text" class="validate" />
                                    <label for="nombre">Nombre del Producto</label>
                                </div>
                            </div>
                            <div style={{margin:'5% 15%'}}>
                                <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="precio" id="precio" type="number" step=".01" class="validate" />
                                    <label for="precio">Precio Menudeo</label>
                                </div>
                            </div>
                            <div style={{margin:'5% 15%'}}>
                                <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="precioMayoreo" id="precioMayoreo" type="number" step=".01" class="validate" />
                                    <label for="precioMayoreo">Precio Mayoreo</label>
                                </div>
                            </div>
                            <div style={{margin:'0 15%'}}>
                                <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="cantidad" id="cantidad" type="number" class="validate" />
                                    <label for="cantidad">Cantidad en Stock</label>
                                </div>
                            </div>
                            <div style={{margin:'5% 15%'}}>
                                <div class="input-field col s12">
                                    <input required onChange={this.leerDato} name="especificacion" id="especificacion" type="text" class="validate" />
                                    <label for="especificacion">Espicificación</label>
                                </div>
                            </div>

                            <button id="font-button" class="btn waves-effect waves-light" type="submit" name="action" style={{padding:'0 50px 0 50px', background:'#e57373 ', marginTop:'25px'}}>AGREGAR</button>
                            <Link to={`/admin/productos/${this.props.match.params.id}`} id="font-button" class="btn waves-effect waves-light" style={{padding:'0 50px 0 50px', marginLeft:'20px', background:'#e57373 ', marginTop:'25px'}}>REGRESAR</Link>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }
}

NuevoProducto.protoType = {
    firestor : PropTypes.object.isRequired
}

export default firestoreConnect()(NuevoProducto);