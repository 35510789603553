import React, { Fragment, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import { langContext } from '../Context/langContext';

import otoplastia1 from '../Images/4-Cirugia-facial/5-OTOPLASTIA/1-PORTADA-1400.png';
import otoplastia6 from '../Images/4-Cirugia-facial/5-OTOPLASTIA/1-PORTADA-1400-en.png';
import otoplastia2 from '../Images/4-Cirugia-facial/5-OTOPLASTIA/14.png'
import otoplastia3 from '../Images/4-Cirugia-facial/5-OTOPLASTIA/CASOS-REALES/1.jpg'
import otoplastia4 from '../Images/4-Cirugia-facial/5-OTOPLASTIA/CASOS-REALES/2.jpg'
import otoplastia5 from '../Images/4-Cirugia-facial/5-OTOPLASTIA/CASOS-REALES/3.jpg'

const Otoplastia = props => {

    const idioma = useContext(langContext).obtenerLenguaje();

    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });

    return (

        <Fragment>

            {idioma === "es-MX" ? (
                <img className="img-ma" src={otoplastia1} alt=""></img>
            ) : (
                <img className="img-ma" src={otoplastia6} alt=""></img>
            )}

            <h4 style={{marginTop:"-80px"}}>
                <FormattedMessage
                id="titulos.1"
                defaultMessage="Descripción"
                />
            </h4>

            <div className='contenedor txt-separacion'>
                <p className='txt-ma' style={{marginTop:"60px"}}>
                    <FormattedMessage
                    id="otoplastia.1"
                    defaultMessage="Tiene como objetivo corregir y remodelar las orejas prominentes, logrando mejorar su proporción y 
                                    posición."
                    />
                </p>

                <p className='txt-ma'>
                    <FormattedMessage
                    id="otoplastia.2"
                    defaultMessage="Se realiza una incisión  en el surco posterior de la oreja para poder remodelar el cartílago y llevarlo 
                                    a su posición normal. De este modo, se consigue una oreja más natural, con una cicatriz completamente 
                                    oculta en la parte posterior."
                    />
                </p>

            </div>


            <div className="flexbox contenedor" style={{marginTop: "100px", marginBottom: "100px"}}>

                <div className='flexbox-hijo order-1 center-image'>
                    <img className="img-lipo2" src={otoplastia2} alt=""></img>
                </div>

                <div className='flexbox-hijo order-2'>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.2"
                        defaultMessage="¿Quiénes son candidatos?"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="otoplastia.3"
                        defaultMessage="Pacientes que tengan orejas prominentes, es decir que el ángulo de la oreja esté aumentado."
                        />
                    </p>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.3"
                        defaultMessage="Preparación/Anestesia"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="otoplastia.4"
                        defaultMessage="Cirugía con anestesia local y sedación."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="otoplastia.5"
                        defaultMessage="Procedimiento ambulatorio."
                        />
                    </p>

                    <h4 className='cambio-posicion'>
                        <FormattedMessage
                        id="titulos.4"
                        defaultMessage="Cuidados Post-Operatorios"
                        />
                    </h4>
                    <p className='txt-ma2'>
                        <FormattedMessage
                        id="otoplastia.6"
                        defaultMessage="Usar banda compresiva para las orejas todo el día por 2 semanas, después solo por la noche otras 2 semanas."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="otoplastia.7"
                        defaultMessage="Reposo de 10 a 14 días."
                        />
                    </p>
                    <p className='txt-ma2' style={{marginTop:"-15px"}}>
                        <FormattedMessage
                        id="otoplastia.8"
                        defaultMessage="Regreso a la vida normal a partir de las 2 semanas."
                        />
                    </p>

                </div>

            </div>


            <h4 style={{color:"#0E766E"}}>
                <FormattedMessage
                id="titulos.5"
                defaultMessage="¿Necesitas un procedimiento personalizado?"
                />
            </h4>

            <div style={{display:"flex", justifyContent:"center", marginTop:"50px", marginBottom:"50px"}}>
                <a class="waves-effect waves-light btn btn-cita-3" href='/cita'>
                    <FormattedMessage
                    id="index.btn-2"
                    defaultMessage="¡Agenda una cita!"
                    />
                </a>
            </div>


            <h4>
                <FormattedMessage
                id="titulos.6"
                defaultMessage="Casos reales:"
                />
            </h4>

            <div className='container row'>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={otoplastia3} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={otoplastia4} alt=""></img>
                    </div>
                </div>

                <div className='col s4 m4'>
                    <div className='materialboxed img-galeria5 img-espacio'>
                        <img className='imagenes_galeria' src={otoplastia5} alt=""></img>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Otoplastia;