import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';

import ins1 from '../Images/1-INICIO/Innovare-transparente.png';

import MapaDatos from '../Components/MapaDatos'
import InstalacionesImagenes from '../Components/Instalaciones'
import Instalaciones_Galeria2 from '../Components/Instalaciones_Galeria2';


const Instalaciones = props => {

    /* Se inicia el carrusel de imagenes */ 
    useEffect(() => {
        var elems_2 = document.querySelectorAll('.materialboxed');
        var inst = M.Materialbox.init(elems_2);
        console.log(inst);
    });
    

    return (
        <Fragment>
            
            <p className='titulo' style={{textAlign:"center", fontSize:"44px", color:"#0E766E", marginTop:"100px"}}>
                <FormattedMessage
                id="ins.1"
                defaultMessage="Instalaciones:"
                />
            </p>

            <p className='titulo' style={{textAlign:"center", fontSize:"44px", color:"#0E766E", marginTop:"-50px"}}>
                <FormattedMessage
                id="ins.1.1"
                defaultMessage="Hospital"
                />
            </p>

            <img className='img-ins1' src={ins1}></img>

            <div className='container ins-limitar'>
                <InstalacionesImagenes/>
            </div>

            <p className='titulo' style={{textAlign:"center", color:"#0E766E", fontSize:"44px"}}>
                <FormattedMessage
                id="ins.5"
                defaultMessage="Consultorio"
                />
            </p>

            <div className='container ins-limitar'>
                <Instalaciones_Galeria2/>
            </div>


            <p className='titulo' style={{textAlign:"center", color:"#0E766E", fontSize:"44px"}}>
                <FormattedMessage
                id="ins.2"
                defaultMessage="Contáctanos"
                />
            </p>

            <MapaDatos/>

        </Fragment>
    )
}

export default Instalaciones;