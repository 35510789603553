import React, { Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import cc1 from '../Images/ICONOS/1.png';
import cc2 from '../Images/ICONOS/10.png';
import cc3 from '../Images/ICONOS/11.png';
import cc4 from '../Images/ICONOS/12.png';


const Contorno_Corporal = props => {

    return (
        <Fragment>

            <h1 style={{marginBottom: "100px", color:"#0E766E"}}>
                <FormattedMessage
                id="cc.3"
                defaultMessage="Contorno Corporal"
                />
            </h1>

            <div className="padre_1" style={{marginBottom:"200px"}}>

                <a href='/lipoescultura' style={{color:"black"}}>
                    <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={cc1} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="cc.5"
                            defaultMessage="Lipoescultura"
                            />
                        </p>
                    </div>
                </a>

                <a href='/abdominoplastia' style={{color:"black"}}>
                    <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={cc2} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="cc.6"
                            defaultMessage="Abdominoplastia"
                            />
                        </p>
                    </div>
                </a>
                
            
                <a href='/mommy-make-over' style={{color:"black"}}>
                    <div className="hijo_3 sombra-2 center-image" style={{height:"230px", justifyContent:"center"}}>
                        <img style={{width:"35%"}} src={cc3} alt=""></img>
                        <p style={{fontSize:"22px", marginTop:"5px"}}>
                            <FormattedMessage
                            id="mamas.7"
                            defaultMessage="Mommy Make Over"
                            />
                        </p>
                    </div>
                </a>
        
            </div>

        </Fragment>
    )
}

export default Contorno_Corporal;